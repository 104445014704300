.page_block.content_blocks_odd_even {
	.item {
		display: flex;
		@include make-row();
		@include media-breakpoint-up(md) {
			margin: 80px 0;
		}

		&:nth-of-type(odd) {
			.item-media {
				order: 1;
			}
		}

		.item-content {
			@include make-col(12);

			@include media-breakpoint-up(lg) {
				@include make-col(6);
			}

			margin: 0 auto;
			padding: 0 40px;
		}

		.item-media {


			@include make-col(12);

			@include media-breakpoint-up(lg) {
				@include make-col(6);
			}

			img {
				object-fit: cover;
				box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
				border-radius: 8px;
				border: 1px solid rgba(255, 255, 255, 0.5);
			}
		}
	}
}

.home-contentblock-slider:not(.owl-loaded) {
	display: flex;
	justify-content: center;
}

.contact-intro .contentblock{

}
