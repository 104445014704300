
&.vacancy_vacancy_overview, &.vacancy_auditions {
	main {
		z-index: 1;

		@include media-breakpoint-up(xl) {
			margin-top: 83px;
		}
		.intro-section {
			margin-top: 0;
			padding: 90px 0 70px !important; // !important needed to override base css

			@include media-breakpoint-up(sm) {
				padding: 90px 0 90px !important; // !important needed to override base css
			}
			@include media-breakpoint-up(xl) {
				padding: 110px 0 100px !important; // !important needed to override base css
			}

			.container-default {
				.container-holder {
					h1,
					h2,
					p {
						color: $white;
					}
					h1 {
						margin-bottom: 5px;
					}
					h2 {
						font-size: $font-size-base * 1.5;
						margin-bottom: 20px;
					}
					p {
						max-width: 680px;
						margin-bottom: 30px;
						font-weight: 500;
					}
					.tag-list {
						@extend .list-item;
					}
				}
			}
		}
		.info {
			padding: 80px 0;

			.container-two-columns {
				.container-holder {
					.column.one {
						margin: auto;

						@include media-breakpoint-up(lg) {
							@include make-col(4);
						}
					}
					.column.two {
						margin-bottom: 0;

						@include media-breakpoint-up(lg) {
							@include make-col(8);
						}
						h1 {
							margin-bottom: 5px;
						}
						h2 {
							font-size: $font-size-base * 1.5;
						}
						p {
							max-width: 722px;
						}
					}
				}
			}
		}
		.content {
			background: $secondary;
			padding: 70px 0 90px;

			@include media-breakpoint-up(sm) {
				padding: 90px 0 100px;
			}

			h1,
			p {
				color: $white;
			}
			p {
				max-width: 517px;
				font-weight: 500;
				margin-bottom: 20px;
			}
			.tag-list {
				@extend .list-item;

				.tag-list-item {
					width: auto;

					&.current {
						background: $white;
					}
				}
			}
			.page_block.vacancy_filter {
				margin-bottom: 60px;
			}

			.grid.page_block.vacancy_overview {
				.item {
					margin-bottom: 0;
					padding: 0;

					@include make-col(12);

					.card {
						background: transparent;
						border: none;

						&-body {
							background: transparent;

							.card-caption {
								.card-title-link,
								.card-subtitle,
								.card-description {
									color: $white;
								}
								.card-title-link {
									margin-bottom: 5px;

									&:hover {
										.card-title {
											color: $base-1;
										}
									}
								}
								.card-description {
									&-content {
										max-width: 624px;
										font-weight: 500;
									}
								}
							}
							.card-buttons {
								margin-top: 10px;
							}
						}
					}
				}
			}
		}
		.story-section {
			padding: 80px 0;

			@include media-breakpoint-up(sm) {
				padding: 100px 0;
			}
			.container-two-columns {
				@extend .container-fluid;

				&.story-container.text-left {
					margin-bottom: 40px;

					@include media-breakpoint-up(lg) {
						margin-bottom: 80px;
					}

					.container-holder {
						.column.one {
							@include media-breakpoint-up(sm) {
								@include make-col(12);
							}
							@include media-breakpoint-up(lg) {
								@include make-col(5);
							}
							h1,
							h2 {
								display: block;

								@include media-breakpoint-up(lg) {
									max-width: 466px;
								}
							}
							h1 {
								font-size: $font-size-base * 1.875;

								@include media-breakpoint-up(sm) {
									font-size: $font-size-base * 2.25;
								}
								@include media-breakpoint-up(lg) {
									margin-top: 50px;
								}
							}
							h2 {
								font-size: $font-size-base * 1.25;
								margin-bottom: 20px;

								@include media-breakpoint-up(sm) {
									font-size: $font-size-base * 1.5;
								}
							}
							p {
								//@extend .view_offer;
							}
						}
						.column.two {
							@include media-breakpoint-up(sm) {
								@include make-col(12);
							}
							@include media-breakpoint-up(lg) {
								@include make-col(7);
							}
							.page_block.resource_image {
								height: 100%;
								img {
									width: 100%;
									height: 100%;
									object-fit: cover;
									box-shadow: 3px 3px 6px rgba($black, 0.16);
									border-radius: 8px;
									border: 1px solid rgba($white, 0.5);
								}
							}
							.page_block.vimeo_video {
								position: relative;
								padding-bottom: 56.25%;
								height: 0;
								overflow: hidden;
								max-width: 100%;
								box-shadow: 3px 3px 6px rgba($black, 0.16);
								border-radius: 8px;
								border: 1px solid rgba($white, 0.5);
								iframe {
									position: absolute;
									top: 0;
									left: 0;
									width: 100%;
									height: 100%;

								}
							}
						}
					}
				}
				&.story-contiainer.text-right {
					text-align: left !important; // !important needed to override base css

					.container-holder {
						.column.one {
							@include media-breakpoint-down(md) {
								order: 2;
							}
							@include media-breakpoint-up(sm) {
								@include make-col(12);
							}
							@include media-breakpoint-up(lg) {
								@include make-col(4);
							}
							.page_block.resource_image {
								height: 100%;

								img {
									width: 100%;
									height: 100%;
									object-fit: cover;
									box-shadow: 3px 3px 6px rgba($black, 0.16);
									border-radius: 8px;
									border: 1px solid rgba($white, 0.5);
								}
							}
						}
						.column.two {
							@include media-breakpoint-down(md) {
								order: 1;
							}
							@include media-breakpoint-up(sm) {
								@include make-col(12);
							}
							@include media-breakpoint-up(lg) {
								@include make-col(8);
							}
							h1 {
								font-size: $font-size-base * 1.875;

								@include media-breakpoint-up(sm) {
									font-size: $font-size-base * 2.25;
								}
								@include media-breakpoint-up(lg) {
									margin-top: 50px;
								}
							}
							h2 {
								font-size: $font-size-base * 1.25;
								margin-bottom: 20px;

								@include media-breakpoint-up(sm) {
									font-size: $font-size-base * 1.5;
								}
							}
							p {
								//@extend .view_offer;
								max-width: 722px;
							}
						}
					}
				}
			}
		}

		section.content {
			.page_block.contentblock {
				.card {
					.card-title-link:hover {
						color: darken($secondary, 20);
					}
				}

				.owl-nav {
					.owl-prev, .owl-next {
						color: $secondary;

						&:hover {
							color: $secondary;
						}
					}
				}
			}
		}
	}
}

&.vacancy_vacancy_detail {
	.content {}

	.vacancy_detail {
		@include make-row();
		@include media-breakpoint-up(xl) {
			margin-top: 85px;
		}

		.info {
			@include make-col-ready();
			@include make-col(12);

			@include media-breakpoint-up(lg) {
				@include make-col(6);
			}

			@extend .mb-4;
			@extend .pr-lg-5;
		}

		.info-extra {
			@include make-col-ready();
			@include make-col(12);

			@include media-breakpoint-up(lg) {
				@include make-col(6);
			}

			@extend .mb-4;
			@extend .pl-lg-5;
		}
	}
}
