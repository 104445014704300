.eyecatcher {
	.owl-carousel {
		min-height: $carousel-min_height;

		@include media-breakpoint-up(lg) {
			min-height: $carousel-min_height_lg;
		}
		.owl-stage-outer {
			.owl-stage {
				.owl-item {
					position: relative;

					&::before {
						content: "";
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						background: rgba($black, 0.21);
					}
					.item {
						min-height: $carousel-min_height;
						@include media-breakpoint-up(lg) {
							min-height: $carousel-min_height_lg;
						}
					}
					.owl-container {
						max-width: 700px;
						z-index: 1;

						.owl-caption {
							position: relative;
							text-align: center;
							.owl-title {
								text-align: center;
								font-size: $font-size-base * 2;
								text-shadow: none;

								@include media-breakpoint-up(md) {
									font-size: $font-size-base * 3.75;
								}
							}
							.owl-subtitle {
								display: none;
								font-size: $font-size-base * 1.875;
								text-align: center;
								text-shadow: none;

								@include media-breakpoint-up(sm) {
									display: block;
								}
							}
						}
					}
				}
			}
		}
	}
}

.slider.home-contentblock-slider {
	&.owl-carousel {
		min-height: auto;

		.owl-stage-outer {
			margin-left: 70px;
			margin-right: -5px;

			@include media-breakpoint-up(md) {
				margin-left: 130px;
			}
			.owl-item {
				img {
					border-radius: 8px;
				}
			}
		}
		.owl-nav {
			left: 22px;
			display: flex;
			flex-direction: column;
			width: 50px;

			@include media-breakpoint-up(md) {
				left: 40px;
			}
			.owl-prev {
				margin-bottom: 10px;
			}
			.owl-next,
			.owl-prev {
				@extend .flex-center;
				position: static;
				width: 36px;
				height: 36px;
				border-radius: 50%;
				background: $white;
				box-shadow: 3px 3px 6px rgba($black, 0.16);

				@include media-breakpoint-up(md) {
					width: 48px;
					height: 48px;
				}
			}
		}
		.owl-dots {
			display: flex;
			height: 10px;
			background: rgba($white, 0.6);
			flex: 1;
			padding: 0;
			border-radius: 4px;
			margin: 20px 20px 0 95px;

			@include media-breakpoint-up(md) {
				margin-right: 90px;
				margin-left: 130px;
			}
			.owl-dot {
				border: none;
				box-shadow: none;
				flex: 1;
				list-style: none;

				&.active {
					border-radius: 6px;
					background: $white;
				}
				span {
					display: none !important;
				}
				&:first-child {
					border-top-left-radius: 6px;
					border-bottom-left-radius: 6px;
				}
				&.active ~ .owl-dot {
					background: transparent;
				}
			}
		}
	}
}

&.landing_page_park, .show_detail_other_shows {
	.main {
		.owl-carousel {
			.owl-stage {
				.owl-item {
					max-width: 262px;
				}
			}
		}
	}
}

.owl-carousel {
	&.owl-brands-slider {
		.owl-nav {
			right: 1em;
			left: 1em;

			button {
				width: 2.5rem;
				height: 2.5rem;
				border-radius: 50%;
				background-color: $primary;

				i {
					top: 50%;
					left: 50%;
					position: absolute;
					color: #fff;
					font-size: 1.5rem;
					transform: translate(-50%, -50%);
				}
			}
		}
		.owl-item {
			.item {
				width: 75%;
				.card {

					.card-image {
						box-shadow: none;
					}

					.card-body {
						display: flex !important;

						.card-title {
							color: $primary;
							font-size: 1rem;
							line-height: 1.2;
							text-transform: uppercase;
							text-align: center;
						}
					}
				}
			}
		}
	}
}
