&.show_detail {
    .characters-section {
        text-align: center;
        margin-bottom: 3rem;

        .contentblock {
            margin-top: 3rem;

			&.owl-carousel {
				min-height: 0;
				padding: 0;

				.item {
					min-height: 0;
					padding: 0;

					.card {
						background: none;
						border: none;
						border-radius: 0;
						text-decoration: none;

						.card-image {
							margin-bottom: 15px;
							background: none;
							border-radius: 100%;
							aspect-ratio: 1;
							overflow: hidden;

							img {
								height: 100%;
								object-fit: cover;
								transition: 0.5s;
							}

							&:hover {
								img {
									transform: scale(1.05);
								}
							}
						}

						.card-body {
							padding: 0;
							background: none;

							.card-caption {

								.card-title,
								.card-subtitle {
									margin-bottom: 0.25rem;
									color: $black;
									font-size: 17px;
									font-weight: 500;
									text-align: center;
								}
							}

							.card-buttons {
								display: none;
							}
						}
					}
				}

				.owl-dots {
					.owl-dot {
						span {
							border-color: $primary;
						}

						&:hover {
							span {
								background: $primary;
							}
						}

						&.active {
							span {
								background: $primary;
								border-color: $primary;
							}
						}
					}
				}
			}
		}
    }

	// fancybox
	.fancybox-card-title {
		font-size: 24px;
		font-weight: 600;
	}

	.fancybox-card-subtitle {
		font-size: 18px;
		font-weight: 500;
	}

	.fancybox-card-description {
		font-size: 16px;
	}
}
