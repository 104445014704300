section {
	&.intro-section {
		background: $primary;
		padding: 70px 0 0;
		margin-top: 5px;

		@include media-breakpoint-up(sm) {
			padding: 90px 0 0;
		}

		.container-default {
			.container-holder {
				.page_block {
					.info {
						h1 {
							position: relative;

							&::before {
								background: url("/images/eyes-left.svg") no-repeat top/100%;
								content: "";
								position: absolute;
								top: -65px;
								left: -20px;
								width: 85px;
								height: 73px;

								@include media-breakpoint-up(xl) {
									left: -60px;
								}
							}
						}

						h1,
						h2,
						p {
							color: $white;
						}

						h2 {
							font-size: $font-size-base * 1.5;
						}

						p {
							font-size: $font-size-base;
							margin: 20px 0 38px;

							@include media-breakpoint-up(lg) {
								margin: 20px 0 58px;
							}
						}

						ul.tag-list {
							@extend .list-item;
						}
					}

					.info-extra {
						margin-top: 30px;

						@include media-breakpoint-up(lg) {
							margin-top: 110px;
						}

						.info-extra-list {
							@extend .info-list;
						}

						a {
							@extend .btn-highlight;
							margin-top: 30px;
						}
					}
				}
			}
		}
	}

	&.map-section {
		background: url("/images/roadmap.svg") no-repeat center/100%;
		padding: 100px 30px 30px 30px;
		position: relative;

		@include media-breakpoint-up(sm) {
			padding-top: 155px;
		}
		@include media-breakpoint-up(xl) {
			padding-top: 12.5vw;
		}

		&::before {
			background: url("/images/curve-green-transparent.svg") no-repeat top/100%;
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			right: 0;
			z-index: 11;
		}

		&::after {
			background: rgba($secondary, 0.6);
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			bottom: 0;
			right: 0;
			z-index: 1;
		}

		.container-default {
			position: relative;
			z-index: 12;

			.page_block {
				h3 {
					display: block;
					max-width: 400px;
					margin-left: auto;
					margin-right: auto;
					color: $white;
					text-align: center;
					position: relative;

					&::before {
						background: url("/images/eyes-left.svg") no-repeat top/100%;
						content: "";
						position: absolute;
						top: -65px;
						left: -20px;
						width: 80px;
						height: 50px;

						@include media-breakpoint-up(xl) {
							left: -60px;
							width: 120px;
							height: 73px;
						}
					}
				}
			}
		}
	}

	&.story-section {
		background: $white;
		padding: 0;

		@include media-breakpoint-up(sm) {
			padding: 0;
		}

		.container-default {
			@extend .container-fluid;

			@include media-breakpoint-up(sm) {
				padding: 0 30px !important; // !important needed to override base css
			}

			.container-holder {
				.show-story-item-media {
					text-align: center;
				}

				.show-story-item-content {
					@include media-breakpoint-up(lg) {
						max-width: 576px;
						margin: 0 auto;
					}
					@include media-breakpoint-up(xl) {
						max-width: 640px;
					}

					h4 {
						font-size: $font-size-base * 1.25;

						@include media-breakpoint-up(sm) {
							font-size: $font-size-base * 1.5;
						}
					}

					p {
						font-size: $font-size-base_sm;
						margin-top: 20px;
					}
				}

				.slider {
					min-height: 100%;

					.owl-stage-outer {
						.owl-stage {
							&:not(:empty) {
								padding-top: 80px;
								@include media-breakpoint-up(sm) {
									padding-top: 100px;
								}
							}

							.show-gallery-wrapper.item {
								@include media-breakpoint-up(sm) {
									display: block !important; // !important needed to override base css
								}
							}
						}
					}

					.owl-item {
						.item {
							.video {
								.card-image {
									position: relative;

									&:after {
										content: "\f144";
										font-family: $font-awesome;
										font-weight: 100;
										color: $white;
										font-size: $font-size-base * 5;
										position: absolute;
										top: 30%;
										right: 0;
										bottom: 0;
										left: 0;
										margin: auto;
										text-align: center;
										pointer-events: none;
									}
								}
							}

							.card-image {
								position: relative;

								&:after {
									content: "\f002";
									font-family: $font-awesome;
									color: $white;
									font-size: $font-size-base * 1.5;
									position: absolute;
									right: 10px;
									bottom: 10px;
								}

								.card-img {
									height: 300px;
									object-fit: cover;
								}
							}
						}
					}

					.owl-nav {
						bottom: 65%;

						.owl-prev {
							margin-bottom: 10px;
							left: -25px;
						}

						.owl-next {
							left: -25px;
							top: 55px;
						}

						button {
							box-shadow: 3px 3px 6px rgba($black, 0.16);
							width: 48px;
							height: 48px;
							border-radius: 50%;
						}

						.owl-next,
						.owl-prev {
							color: $secondary;
							font-size: $font-size-base;
							@include media-breakpoint-up(sm) {
								font-size: $font-size-base * 2;
							}
						}

						.owl-next.disabled,
						.owl-prev.disabled {
							opacity: 1 !important;

							i {
								opacity: 0.5;
							}
						}
					}

					.owl-dots {
						display: flex;
						height: 10px;
						box-shadow: inset 0px 1px 4px rgba($black, 0.16);
						flex: 1;
						padding: 0;
						border-radius: 4px;
						margin: 20px 10px 0 95px;

						.owl-dot {
							border: none;
							box-shadow: none;
							flex: 1;
							list-style: none;

							&.active {
								border-radius: 8px;
								background: $secondary;
							}

							span {
								display: none !important;
							}

							&:first-child {
								border-top-left-radius: 6px;
								border-bottom-left-radius: 6px;
							}

							&.active ~ .owl-dot {
								background: transparent;
							}
						}
					}
				}

				.show_detail_gallery {
					padding-bottom: 40px;

					@include media-breakpoint-up(sm) {
						padding-bottom: 50px;
					}
				}
			}
		}

		.container-default:first-child {
			padding: 0 30px 0 60px !important; // !important needed to override base css
		}
	}

	&.shop-section {
		background: $secondary;
		padding: 70px 0 140px;
		position: relative;

		@include media-breakpoint-up(sm) {
			padding: 100px 0 220px;
		}
		@include media-breakpoint-up(xl) {
			padding: 100px 0;
		}

		.container-default {
			.container-holder {
				h1 {
					color: $white;
					position: relative;

					&::before {
						background: url("/images/eyes-left.svg") no-repeat top/cover;
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						margin-left: 60px;
						width: 85px;
						height: 51px;
						transform: translate(-100%, -60px);

						@include media-breakpoint-up(xl) {
							margin-left: 30px;
						}
					}
				}

				p {
					font-size: $font-size-base;
					color: $white;
					max-width: 517px;
					margin-bottom: 35px;
				}

				a {
					@extend .btn-highlight;
				}

				.show-detail-bottom-image {
					z-index: 1;
					position: absolute;
					transform: translateY(35%) !important;
					bottom: 0;
					right: 0;
					@include media-breakpoint-down(md) {
						right: 30px;
					}
					@include media-breakpoint-up(lg) {
						right: 60px;
					}
				}
			}
		}
	}

	&.characters-section {
		background: $white;
		padding: 80px 0 50px;

		@include media-breakpoint-up(lg) {
			padding: 110px 0 70px;
		}

		.container-default {
			@extend .container-fluid;

			.container-holder {
				.show_detail_characters {
					.show_detail_characteristics_intro {
						padding: 0 !important;

						p {
							max-width: 517px;
						}
					}

					.grid.page_block {
						padding-top: 70px;

						.item {
							@include media-breakpoint-up(lg) {
								@include make-col(4);
							}
						}

						.show-character-wrapper {
							.show-character-item {
								.card-image {
									border: 1px solid rgba($base-4, 0.5);

									.card-img {
										display: flex;
										margin: auto;
									}
								}

								.card-body {
									padding: 15px 0;

									.card-title {
										color: $primary;
									}

									.card-description {
										&-content {
											p {
												font-size: $font-size-base_sm;
											}
										}
									}
								}
							}
						}
					}
				}

				.grid.page_block {
					.item {
						@include media-breakpoint-up(lg) {
							@include make-col(4);
						}

						.card {
							@extend .contentblock-card;
						}
					}
				}
			}
		}

		.info {
			.container-holder {
				@extend .container;
				padding: 0 !important;

				h1,
				p {
					max-width: 600px;
				}

				p {
					padding-bottom: 70px;
				}
			}
		}
	}

	&.events-section {
		background: $white;
		padding: 70px 0 80px;

		@include media-breakpoint-up(sm) {
			padding: 90px 0 100px;
		}

		.container-default {
			.container-holder {
				.show_detail_events {
					h1 {
						font-size: $font-size-base * 1.875;

						@include media-breakpoint-up(sm) {
							font-size: $font-size-base * 2.25;
						}
					}

					h1,
					h2 {
						color: #000;
					}

					h2 {
						font-size: $font-size-base * 1.25;

						@include media-breakpoint-up(sm) {
							font-size: $font-size-base * 1.5;
						}
					}

					.btn {
						@extend .btn-highlight;
						margin-top: 40px;
					}
				}
			}
		}
	}

	&.contentblocks-section {
		background: $primary;
		padding: 80px 0 100px;
		position: relative;
		margin-top: 4px;

		@include media-breakpoint-up(sm) {
			padding: 90px 0 160px;
		}

		/*
		&::after {
			background: url("/images/curve-transparent-green.svg") no-repeat bottom/100%;
			content: "";
			position: absolute;
			width: 100%;
			height: 135px;
			bottom: -1px;
			right: 0;
		}
		*/

		.container-default,
		.container-two-columns {
			.container-holder {
				h1,
				h2,
				h3 {
					color: $white;
				}

				h2,
				h3 {
					font-size: $font-size-base * 1.5;
					margin-bottom: 30px;
				}

				p {
					max-width: 680px;
					color: $white;
				}

				.show_detail_other_shows {
					.show-character-item {
						.card-image {
							.card-image-link {
								.card-img {
									border-radius: 8px;
								}
							}
						}

						.card-body {
							padding: 15px 0;

							.card-caption {
								.card-title-link {
									text-decoration: none;

									&:hover {
										.card-title {
											color: $base-1;
										}
									}
								}

								.card-title {
									margin-bottom: .75rem !important;
								}

								.card-title,
								.card-subtitle {
									color: $white;
								}
							}
						}
					}

					.owl-nav {
						left: -55px !important;
						@include media-breakpoint-up(sm) {
							left: -80px !important;
						}
					}
				}

				.slider {
					padding-top: 60px;
					min-height: 100%;

					.owl-nav {
						top: 60px;
						left: 5px;
						bottom: auto;
						@include media-breakpoint-up(sm) {
							left: 40px;
						}

						button {
							display: flex;
							align-items: center;
							justify-content: center;
							background: $white;
							width: 48px;
							height: 48px;
							line-height: 48px;
							border-radius: 50%;
							box-shadow: 3px 3px 6px rgba($black, 0.16);
							font-size: $font-size-base;
							@include media-breakpoint-up(sm) {
								font-size: $font-size-base * 2;
							}
						}

						.owl-next {
							left: 0;
							top: 55px;
						}
					}

					.owl-dots {
						margin: 0;

						.owl-dot {
							span {
								border-color: $white;
							}

							&:hover,
							&.active {
								span {
									border-color: $white;
									background-color: $white;
								}
							}
						}
					}
				}

				a.btn.btn-all-shows.page_block.link {
					@extend .btn-highlight;
					margin: 15px 20px 0 60px;

					@include media-breakpoint-up(sm) {
						margin: 15px 20px 0 130px;
					}
				}

				.column.one {
					.btn-secondary,
					.btn-primary {
						@extend .btn-highlight;
					}

					p {
						margin-bottom: 35px;
					}
				}

				.column.two {
					.resource_image {
						margin-bottom: 30px;
					}
				}
			}
		}

		.contentblock {
			padding: 0 15px !important;

			@include media-breakpoint-up(xl) {
				padding: 0 0 0 130px !important;
			}
		}
	}

	&.intro {
		background: $primary;
		margin-top: 5px;
		padding: 80px 0 !important; // !important needed to override base css

		@include media-breakpoint-up(sm) {
			padding: 100px 0 !important; // !important needed to override base css
		}

		.container-default {
			.container-holder {
				max-width: 560px;
				display: flex;
				justify-content: flex-start;
				flex-direction: column;

				h1,
				p {
					color: $white;
				}
			}
		}
	}

	&.bg-primary {
		padding: 80px 0 0 !important;
		background: $primary;

		h1,
		h2,
		h3,
		.card-title,
		.card-subtitle,
		p {
			color: $white !important;
		}

		.page_block.contentblock {
			margin-top: 50px;
		}

		.slider {
			min-height: 100%;
		}
	}

	&.home-blocks {
		background: $primary;
		position: relative;
		padding: 50px 0 80px !important; // !important needed to override base css

		@include media-breakpoint-up(sm) {
			padding: 50px 0 70px !important; // !important needed to override base css
		}

		.slider.owl-carousel {
			min-height: 100%;

			.owl-item {
				@include media-breakpoint-down(xs) {
					.item {
						max-width: 350px;

						.card.card-wide .card-body {
							max-width: 70%;
						}
					}
				}
				@media screen and(max-width: 375px) {
					img {
						max-width: 80%;
					}
				}
				@include media-breakpoint-down(sm) {
					flex-direction: column;

					.card {
						display: block;
					}
					img {
						//max-height: 300px;
						object-fit: cover;
					}
				}
				@include media-breakpoint-up(md) {
					.card-image {
						@include make-col(5);
					}
					.card-body {
						@include make-col(7);
					}
				}
			}
		}
	}

	&.webshop-content {
		background: #fff;
		padding: 80px 0;
		position: relative;

		@include media-breakpoint-up(sm) {
			padding: 90px 0;
		}
		@include media-breakpoint-up(xl) {
			padding: 90px 0 140px;
		}

		&::after {
			background: url("/images/curve-white-green.svg") no-repeat bottom/100%;
			content: "";
			position: absolute;
			width: 100%;
			height: 135px;
			bottom: -1px;
			right: 0;
		}

		.container-default {
			.container-holder {
				h1 {
					max-width: 300px;
					color: $primary;

					@include media-breakpoint-up(sm) {
						max-width: 100%;
					}
				}

				p {
					color: #000;
					max-width: 517px;
					margin-bottom: 25px;
				}

				.page_block.link {
					@extend .btn-highlight;
					margin-bottom: 30px;
				}

				.page_block.resource_image {
					@include media-breakpoint-up(lg) {
						z-index: 1;
						position: absolute;
						bottom: 0;
						right: 30px;
					}
				}
			}
		}
	}

	&.content-blocks {
		padding: 80px 0;

		@include media-breakpoint-up(sm) {
			padding: 100px 0;
		}

		p {
			max-width: 517px;
		}

		.container-default {
			@extend .container-fluid;

			.container-holder {
				.page_block.grid {
					.item {
						@include media-breakpoint-up(lg) {
							@include make-col(4);
						}

						.card {
							&-body {
								.card-caption {
									.card-title-link {
										order: 1;

										.card-title {
											color: $base-1;
											font-size: $font-size-base * 1.25;

											@include media-breakpoint-up(sm) {
												font-size: $h4-font-size;
											}
										}

										&:hover {
											.card-title {
												color: $primary;
											}
										}
									}

									.card-subtitle {
										order: 2;
										font-size: $font-size-base * 1.5;
										color: $primary;

										@include media-breakpoint-up(sm) {
											font-size: $h2-font-size;
										}
									}

									.card-description {
										order: 3;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	&.contact-content-blocks {
		padding: 70px 0 50px;

		@include media-breakpoint-up(sm) {
			padding: 80px 0;
		}

		p {
			max-width: 517px;
		}

		.container {
			padding-bottom: 60px;

			@include media-breakpoint-up(sm) {
				padding-bottom: 80px;
			}
		}

		.container-default {
			.container-holder {
				.page_block.grid {
					.item {
						@include media-breakpoint-up(lg) {
							@include make-col(4);
						}
					}
				}
			}
		}
	}

	&.search-book-section {
		position: relative;
		background: $secondary;
		text-align: center;
		padding: 100px 0 80px;

		@include media-breakpoint-up(sm) {
			padding: 155px 0 100px;
		}
		@include media-breakpoint-up(xl) {
			padding: 235px 0 100px;
		}

		&::before {
			background: url(/images/curve-green-transparent.svg) no-repeat top/100%;
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			top: -1px;
			right: 0;
		}

		.container-default {
			.container-holder {
				h2 {
					color: $white;
					font-size: $font-size-base * 1.5;

					@include media-breakpoint-up(sm) {
						font-size: $font-size-base * 2.25;
					}
				}
			}
		}
	}

	&.content.news-block {
		.container-default {
			.container-holder {
				.grid.blog_overview {
					.item {
						@include media-breakpoint-up(lg) {
							@include make-col(6);
						}
					}
				}
			}
		}
	}
}

.main {
	overflow: hidden;

	.intro-section {
		padding: 90px 0 50px;

		h1,
		h2,
		h3,
		h4,
		p {
			color: $white;
		}

		p {
			max-width: 680px;
		}

		h4 {
			font-size: $font-size-base * 1.25;
			margin-bottom: 50px;

			@include media-breakpoint-up(sm) {
				font-size: $font-size-base * 1.5;
			}
		}

		.btn {
			@extend .btn-highlight;
		}

		.container-two-columns {
			.container-holder {
				.column.one {
					@include media-breakpoint-up(sm) {
						@include make-col(8);
					}

					p {
						&:nth-child(3) {
							a {
								color: $white;
								text-decoration: underline;

								&:hover {
									font-weight: 600;
								}
							}
						}
					}
				}

				.column.two {
					p {
						iframe {
							width: 100%;
						}
					}

					@include media-breakpoint-up(sm) {
						@include make-col(4);
					}
				}
			}
		}
	}

	.article_2_columns.park-2-columns {
		padding: 80px 0 70px !important;

		@include media-breakpoint-up(sm) {
			padding: 100px 0 90px !important;
		}

		.container-two-columns.item {
			@extend .container-fluid;

			&:first-of-type {
				margin-bottom: 40px;

				.container-holder {
					.column.two {
						@include media-breakpoint-down(md) {
							padding-top: 0 !important;
						}
					}
				}
			}

			&:last-of-type {
				.container-holder {
					.column.one {
						order: 1;

						@include media-breakpoint-up(lg) {
							padding-top: 60px;
						}

						.btn {
							margin-top: 25px;
						}
					}

					.column.two {
						order: 2;
						padding-top: 0;
					}
				}
			}

			.container-holder {
				margin-bottom: 0;
				@include media-breakpoint-up(xl) {
					padding: 0 30px;
				}

				img {
					box-shadow: 3px 3px 6px rgba($black, 0.16);
					border-radius: 8px;
					border: 1px solid rgba($white, 0.5);
					width: 100%;
				}

				.column.two,
				.column.one {
					h2,
					h3,
					p {
						color: $base-1;
					}

					p {
						font-size: $font-size-base_sm;

						&:not(:first-child) {
							@include media-breakpoint-up(lg) {
								max-width: 640px;
							}
						}
					}

					h2 {
						margin-bottom: 10px;

						@include media-breakpoint-up(sm) {
							font-size: $h3-font-size;
						}
						@include media-breakpoint-up(lg) {
							max-width: 640px;
							font-size: $h1-font-size;
						}
					}

					h3 {
						font-size: $font-size-base * 1.25;
						margin-bottom: 20px;

						@include media-breakpoint-up(sm) {
							font-size: $font-size-base * 1.5;
						}
						@include media-breakpoint-up(lg) {
							max-width: 640px;
						}

						&::before {
							content: none;
						}
					}

					.btn {
						@extend .btn-highlight;
						margin-top: 10px;
					}
				}

				.column.two {
					@include media-breakpoint-up(lg) {
						padding-left: 3rem !important;
					}
				}
			}
		}
	}

	.content.bg-dark {
		background: $secondary !important; // !important needed to override base css
		padding: 80px 0 !important;
		position: relative;

		@include media-breakpoint-up(sm) {
			padding: 100px 0 !important; // !important needed to override base css
		}

		.container-default {
			.container-holder {
				h1 {
					position: relative;

					&::before {
						background: url(/images/eyes-left.svg) no-repeat top/100%;
						content: "";
						position: absolute;
						top: -65px;
						left: -20px;
						width: 85px;
						height: 73px;

						@include media-breakpoint-up(xl) {
							left: -60px;
						}
					}
				}

				p {
					max-width: 517px !important;
				}

				.btn {
					margin-top: 25px;
					@extend .btn-highlight;
				}

				.floating-image {
					z-index: 1;
					position: absolute;
					// transform: translateY(35%) !important;
					left: 70%;
					bottom: -7%;
				}
			}
		}
	}

	.tiles-section {
		padding: 100px 0 60px;

		@include media-breakpoint-up(sm) {
			padding: 170px 0 100px;
		}

		.container-default.container {
			&:first-of-type {
				padding-bottom: 80px;

				@include media-breakpoint-up(sm) {
					padding-bottom: 100px;
				}
			}

			&:last-of-type {
				padding-top: 80px;

				@include media-breakpoint-up(sm) {
					padding-top: 100px;
				}

				.container-holder {
					h2 {
						font-size: $h2-font-size;

						@include media-breakpoint-up(sm) {
							font-size: $h3-font-size;
						}
					}

					.btn {
						@extend .btn-highlight;
						margin-top: 20px;
					}
				}
			}

			.container-holder {
				p {
					max-width: 517px;
				}
			}
		}

		.container-default.container-fluid {
			.container-holder {
				.grid.page_block {
					.item {
						margin: 0 0 12px 0 !important; // !important needed to override base css

						@include media-breakpoint-up(xl) {
							@include make-col(4);
						}

						.card {
							border: none !important;

							&-image {
								border: 1px solid rgba($base-4, 0.5);
								background: transparent;
								padding: 50px 30px;
								@extend .flex-center;

								@include media-breakpoint-up(xl) {
									min-height: 418px;
								}

								&-link {
									width: 331px;

									.card-img {
										border-radius: 8px;
									}
								}
							}

							&-body {
								padding: 15px 0;

								.card-caption {
									max-width: 400px;

									.card-title-link {
										&:hover {
											.card-title {
												color: $base-1;
											}
										}
									}

									.card-title {
										color: $primary;
										font-size: $font-size-base * 1.875;

										@include media-breakpoint-up(sm) {
											font-size: $font-size-base * 2.25;
										}
									}

									.card-description {
										&-content {
											p {
												font-size: $font-size-base_sm;
											}
										}
									}
								}

								.card-buttons {
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}

	.contentblocks-section.bg-dark.contact-intro {
		position: relative;
		margin-top: 0;
		padding: 60px 0;
		background: $secondary !important;

		@include media-breakpoint-up(sm) {
			padding: 100px 0 80px;
		}
		@include media-breakpoint-up(lg) {
			padding: 125px 0 80px;
		}
		@include media-breakpoint-up(xl) {
			padding: 180px 0 80px;
		}

		&::before {
			background: url(/images/curve-green-transparent.svg) no-repeat top/100%;
			content: "";
			position: absolute;
			width: 100%;
			height: 145px;
			top: -1px;
			right: 0;
			z-index: 11;
		}

		&::after {
			content: none;
		}

		.owl-nav {
			.owl-next,
			.owl-prev {
				color: $secondary;
			}
		}
	}

	.content.bg-dark.contact-form {
		background: $secondary;
		padding: 80px 0;
		position: relative;

		@include media-breakpoint-up(sm) {
			padding: 100px 0;
		}

		.container-two-columns {
			.container-holder {
				.column.one {
					h1 {
						position: relative;
						margin-bottom: 15px;

						&::before {
							background: url(/images/eyes-left.svg) no-repeat top/100%;
							content: "";
							position: absolute;
							top: -65px;
							left: -20px;
							width: 85px;
							height: 73px;

							@include media-breakpoint-up(xl) {
								left: -60px;
							}
						}
					}

					p {
						color: $white;
						max-width: 517px;
						font-size: $font-size-base;

						&:first-of-type {
							margin-bottom: 45px;
						}

						&:not(:first-of-type) {
							margin-bottom: 10px;
							font-size: $font-size-base * 1.125;

							@include media-breakpoint-up(sm) {
								font-size: $font-size-base * 1.25;
							}

							a {
								color: $white;
								font-weight: 700;
								text-decoration: underline;

								&:hover {
									opacity: 0.8;
								}
							}
						}
					}
				}

				.column.two {
					.page_block.form {
						.form-group {
							.form-control-label {
								font-weight: 500;
							}

							.form-control {
								height: calc(1.5em + 0.75rem + 11px);
								font-size: $font-size-base_sm;
								color: $base-1;
								box-shadow: 0 3px 6px rgba($black, 0.16) !important;
							}

							textarea {
								min-height: 100px;
							}

							button {
								@extend .btn-highlight;
							}
						}

						small,
						a {
							font-weight: $font-size-base_xs;
							color: $white;

							a {
								text-decoration: underline;
							}
						}
					}
				}
			}
		}
	}

	.content.contact-description-section {
		margin: 40px 0;
		padding: 0;

		@include media-breakpoint-up(sm) {
			margin: 60px 0;
		}

		.container-two-columns {
			.container-holder {
				@include media-breakpoint-up(xl) {
					padding: 0 30px !important;
				}

				.column.one {
					display: flex;
					flex-direction: column;

					@include media-breakpoint-down(sm) {
						order: 2;
					}

					p {
						width: 100%;

						img {
							width: 100%;
						}
					}
				}

				.column.two {
					@include media-breakpoint-down(sm) {
						order: 1;
					}

					h2 {
						font-size: $font-size-base * 1.875;
						margin-bottom: 22px;

						@include media-breakpoint-up(sm) {
							font-size: $font-size-base * 2.25;
						}
					}

					p {
						font-size: $font-size-base;
						max-width: 722px;

						&:last-of-type {
							font-size: $font-size-base_sm;
						}
					}

					.custom-list {
						padding-left: 15px !important;

						li {
							font-size: $font-size-base_sm;
							margin-bottom: 5px;
							font-weight: 500;

							&::before {
								content: "\f0a9" !important;
								width: 16px;
								height: 16px;
								font-family: $font-awesome;
								font-weight: normal !important;
								color: $base-1 !important;
								transition: all 300ms;

								@include media-breakpoint-up(sm) {
									font-size: $font-size-base_sm !important;
								}
							}
						}
					}

					iframe {
						height: 350px;
					}
				}

				.column {
					ul {
						@extend .custom-list-item;
					}

					img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}

	.contentblocks-section.bg-dark.contact-intro {
		& + .content.contact-description-section {
			//padding: 0;

			.container-two-columns {
				.container-holder {
					.column.one {
						//padding: 100px 0;
						@include media-breakpoint-down(sm) {
							//padding: 50px 0;
						}
					}

					.column.two {
						//padding: 100px 0;
						@include media-breakpoint-down(sm) {
							//padding: 50px 0;
						}
					}
				}
			}
		}
	}
}

.intro,
.webshop-content,
.footer {
	.container-default.container {
		@include media-breakpoint-down(sm) {
			padding: 0 30px;
		}
		@media screen and (max-width: 340px) {
			padding: 0 30px;
		}
	}
}

&.contact {
	.main {
		.content {
			.container-two-columns {
				.column.one {
					p {
						line-height: 1.8;

						a {
							color: $base-1;
							text-decoration: underline;
							font-weight: 700;

							&:hover {
								color: $primary;
							}
						}
					}
				}

				.column.two {
					.form {
						.form-group {
							margin: 0 0 20px 0;

							.form-control-label {
								font-weight: 500;
							}

							.form-control {
								height: calc(1.5em + 0.75rem + 11px);
								font-size: $font-size-base_sm;
								color: $base-1;
								box-shadow: 0 3px 6px rgba($black, 0.16) !important;
							}

							textarea {
								min-height: 100px;
								margin-bottom: 30px;
							}
						}

						small {

							a {
								text-decoration: underline;
							}
						}
					}
				}
			}
		}
	}
}

&.character_landing_page {
	.story-section {
		padding: 80px 0 !important;

		@include media-breakpoint-up(lg) {
			padding: 100px 0 !important;
		}

		.container-two-columns {
			@include media-breakpoint-down(lg) {
				padding: 0 15px !important; // !important needed to override base css
			}

			&.text-left {
				.column.one {
					order: 2;

					@include media-breakpoint-up(lg) {
						order: 1;
					}
				}

				.column.two {
					order: 1;

					@include media-breakpoint-up(lg) {
						padding-top: 60px;
					}
					@include media-breakpoint-up(lg) {
						order: 2;
					}
				}
			}

			&.text-right {
				text-align: left !important;
				margin-top: 60px;

				.column.one {
					padding-top: 30px;
				}

				.column.two {
					order: 1;

					@include media-breakpoint-up(sm) {
						order: 2;
					}
				}
			}

			.container-holder {
				.column {
					@include make-col(12);

					@include media-breakpoint-up(lg) {
						@include make-col(6);
					}

					p {
						font-size: $font-size-base_sm;
						margin-top: 20px;
					}

					h1,
					p {
						@include media-breakpoint-up(lg) {
							max-width: 640px;
						}
					}

					.resource_image {
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
							box-shadow: 3px 3px 6px rgba($black, 0.16);
							border-radius: 8px;
							border: 1px solid rgba($white, 0.5);
						}
					}
				}
			}
		}
	}

	.shop-section {
		.container {
			.container-holder {
				.column {
					h1,
					p {
						color: $white;
					}
				}
			}
		}
	}
}

&.vacancy_vacancy_overview,
&.vacancy_auditions {
	.main {
		margin-top: 0 !important;

		.story-section {
			.container-two-columns {
				&.story-container.text-left,
				&.story-container.text-right {
					padding: 0 15px;

					@include media-breakpoint-up(lg) {
						padding: 0 30px;
					}

					.container-holder {
						.column.one,
						.column.two {
							img {
								min-height: 100%;
								object-fit: cover;
								box-shadow: 3px 3px 6px rgba($black, 0.16);
								border-radius: 8px;
								border: 1px solid rgba($white, 0.5);
							}

							p {
								iframe {
									width: 100%;
								}
							}
						}
					}
				}

				&.story-container.text-left {
					.container-holder {
						.column.one {
							h1 {
								margin-top: 0 !important;
							}

							h1,
							h2 {
								@include media-breakpoint-up(lg) {
									max-width: 464px;
								}
							}

							@include media-breakpoint-up(lg) {
								@include make-col(6);
							}
						}

						.column.two {
							@include media-breakpoint-up(lg) {
								@include make-col(6);
							}
						}
					}
				}

				&.story-container.text-right {
					text-align: left !important;

					.column {
						@include media-breakpoint-up(sm) {
							h1 {
								font-size: $h3-font-size;
							}
						}
					}

					.column.one {
						order: 2;

						@include make-col(12);

						@include media-breakpoint-up(sm) {
							order: 1;
						}
						@include media-breakpoint-up(lg) {
							@include make-col(6);
						}
					}

					.column.two {
						order: 1;

						@include media-breakpoint-up(sm) {
							order: 2;
						}
						@include make-col(12);
						@include media-breakpoint-up(lg) {
							@include make-col(6);
						}

						h1,
						h2,
						p {
							@include media-breakpoint-up(lg) {
								max-width: 722px;
							}
						}
					}

					p {
						a {
							font-size: $font-size-base_xxs;
							font-weight: 700;
							text-transform: uppercase;
							color: $base-1;
							position: relative;
							padding-right: 20px;

							@include media-breakpoint-up(sm) {
								font-size: $font-size-base_sm;
							}

							&:hover {
								color: $primary;
								text-decoration: none;

								&::after {
									right: -10px;
								}
							}

							&::after {
								position: absolute;
								top: 50%;
								right: 0;
								content: "\f0a9";
								transform: translateY(-50%);
								font-size: $font-size-base_xxs;
								font-family: $font-awesome;
								font-weight: normal;
								transition: all 300ms;

								@include media-breakpoint-up(sm) {
									font-size: $font-size-base_sm;
									margin-top: 2px;
								}
							}

							&.btn {
								color: #fff;

								&:after {
									display: none;
								}

								&:hover {
									color: $primary;
								}
							}
						}
					}
				}
			}
		}
	}
}

&.vacancy_vacancy_overview {
	main.main {
		.info {
			.container-two-columns {
				.container-holder {
					.column {
						&.one,
						&.two {
							@include make-col(12);

							@include media-breakpoint-up(lg) {
								@include make-col(6);
							}
						}

						p {
							font-size: $font-size-base_sm;
							margin-top: 20px;
						}

						h1,
						p {
							@include media-breakpoint-up(lg) {
								max-width: 640px;
							}
						}

						.resource_image {
							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
								box-shadow: 3px 3px 6px rgba($black, 0.16);
								border-radius: 8px;
								border: 1px solid rgba($white, 0.5);
							}
						}
					}
				}
			}
		}

		.vacancies {
			.grid {
				.item {
					.card {
						&-image {
							box-shadow: none;
							border-radius: 0;
							border: 0;

							.card-image-link {
								box-shadow: 3px 3px 6px rgba($black, .16);
								border-radius: 8px;
								border: 1px solid rgba($white, .5);

								.card-img {
									border-radius: 8px
								}
							}
						}
					}
				}
			}
		}
	}
}

&.landing_page_contact {
	.main {
		.intro-section,
		.content.contact-description-section {
			.container-two-columns {
				.container-holder {
					.column {
						&.one,
						&.two {
							margin: 0 0 15px 0;

							@include media-breakpoint-up(sm) {
								margin: 0 !important;
								@include make-col(12);
							}
							@include media-breakpoint-up(lg) {
								@include make-col(6);
							}

							p {
								margin: 0;
								font-size: $font-size-base_sm;

								@include media-breakpoint-up(lg) {
									max-width: 640px;
								}
							}

							h2 {
								@include media-breakpoint-down(sm) {
									font-size: $h2-font-size !important;
								}
							}

							a {
								margin: 10px 10px 10px 0;
							}
						}

						&.two {
							padding-left: 1rem !important;
						}

						.resource_image {
							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
								box-shadow: 3px 3px 6px rgba($black, 0.16);
								border-radius: 8px;
								border: 1px solid rgba($white, 0.5);
							}
						}

						iframe {
							width: 100% !important;
						}
					}
				}
			}
		}

		.content.content-blocks-odd-even {
			padding: 0;

			.container-default {
				.container-holder {
					.content_blocks_odd_even.page_block {
						.item {
							&:nth-of-type(even) {
								.item-media {
									@include media-breakpoint-up(lg) {
										padding-right: 3rem;
									}
								}
							}

							&-content {
								padding: 60px 30px 0 15px;

								.item-content-caption {
									@include media-breakpoint-up(lg) {
										max-width: 640px;
									}
								}

								.card-description {
									p {
										font-size: $font-size-base_sm;
									}
								}
							}

							&-media {
								img {
									width: 100%;
									height: 100%;
								}
							}
						}
					}
				}
			}
		}
	}
}

&.landing_page_contact {
	.content.contact-description-section {
		.container-two-columns {
			.container-holder {
				display: flex;
				align-items: center;
			}
		}
	}
}

&.show_location_detail {
	#events-section {
		padding-top: 110px;
	}
}

// brands-section
.brands-section {
	.slider {
		min-height: auto;

		.owl-stage-outer {
			.owl-stage {
				padding: 10px 0;
			}
		}

		.card {
			border: none;
			border-radius: 0;

			.card-image {
				margin-bottom: 0;
			}

			.card-body {
				display: none !important;
			}
		}
	}
}
