// variables
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Grandstander:wght@500;600;700&display=swap');

$font-family-secondary: 'Grandstander', cursive;
$font-family-tertiary: 'Bebas Neue', sans-serif;

$red: #e60050;
$gold: #d9ad54;
$green: #bbe136;
$green-dark: #375313;
$cream: #fff8ef;

&.shrek_page {
	font-weight: 500;

	@include media-breakpoint-up(lg) {
		font-size: 19px;
	}

	// headings
	h1,
	h2,
	h3 {
		line-height: 1;
		font-weight: 600;
		font-family: $font-family-secondary;
	}

	h4,
	h5,
	h6 {
		font-weight: 700;
	}

	h1 {
		font-size: 42px;

		@include media-breakpoint-up(xl) {
			font-size: 70px;
		}
	}

	h2 {
		font-size: 36px;

		@include media-breakpoint-up(xl) {
			font-size: 60px;
		}
	}

	h3 {
		font-size: 30px;

	}

	h4 {
		font-size: 26px;
	}

	// btn
	.btn {
		padding: 16px 20px;
		border-radius: 20px;
		font-size: 24px;
		font-weight: 600;
		line-height: 1;
		transition: 0.25s;

		@include media-breakpoint-up(lg) {
			font-size: 26px;
			padding: 22px;
		}

		&.btn-primary {
			background-color: $red;
			border-color: $red;
			color: $white;

			&:hover {
				background-color: $green;
				border-color: $green;
				color: $green-dark;
			}
		}
	}

	header.header.sticky {
		@include media-breakpoint-down(lg) {
			position: relative !important;
		}
	}

	// containers
	.container-intro {
		max-width: 800px;
		margin-bottom: 30px;
		text-align: center;
	}

	.container-outro {
		max-width: 800px;
		margin-top: 30px;
		text-align: center;
	}

	.container-ears {
		padding-left: 45px !important;
		padding-right: 45px !important;

		@include media-breakpoint-between(sm, md) {
			padding-left: 55px !important;
			padding-right: 55px !important;
		}

		@include media-breakpoint-up(lg) {
			padding-left: 100px !important;
			padding-right: 100px !important;
		}

		.container-holder {
			position: relative;
			margin-left: 0;
			margin-right: 0;
			padding: 45px 0 15px 0;
			background: url('/images/shrek/ears-line.png') no-repeat center 5px $green;
			border-radius: 20px;

			@include media-breakpoint-up(lg) {
				padding-left: 30px;
				padding-right: 30px;
			}

			h3,
			h4 {
				color: $green-dark;
			}

			a {
				color: $black;
				text-decoration: underline;

				&:hover {
					color: $green-dark;
					text-decoration: none;
				}
			}

			&::before,
			&::after {
				content: '';
				position: absolute;
				top: -39px;
				width: 65px;
				height: 59px;
				background-size: cover;

				@include media-breakpoint-up(lg) {
					top: -82px;
					width: 130px;
					height: 117px;
				}
			}

			&::before {
				background-image: url('/images/shrek/ear-left.png');
				left: -40px;

				@include media-breakpoint-up(lg) {
					left: -84px;
				}
			}

			&::after {
				background-image: url('/images/shrek/ear-right.png');
				right: -40px;

				@include media-breakpoint-up(lg) {
					right: -84px;
				}
			}
		}
	}

	// fancybox
	.fancybox-card-title {
		font-size: 24px;
		font-weight: 600;
	}

	.fancybox-card-subtitle {
		font-size: 18px;
		font-weight: 500;
	}

	.fancybox-card-description {
		font-size: 16px;
	}

	// shrek-header
	// =========================================================================
	/*.shrek-header {
		padding: 15px 0;
		background: url('/images/shrek/header-bg.svg') no-repeat bottom/cover;

		@include media-breakpoint-up(lg) {
			padding: 25px 0;
		}

		.logo {
			max-width: 150px;

			@include media-breakpoint-up(lg) {
				max-width: 180px;
			}

			a {
				@extend .d-block;

				img {
					@extend .w-100;
				}
			}
		}
	}*/

	// shrek-intro-section
	// =========================================================================
	.shrek-intro-section {
		position: relative;
		padding: 8vh 0 136px 0;
		overflow: hidden;

		@include media-breakpoint-up(xl) {
			padding-top: 155px;
			padding-bottom: 177px;
		}

		background: url('/images/shrek/intro-bg.jpg') no-repeat bottom;
		background-size: cover;
		text-align: center;

		.image {
			margin: 30px 0;
		}

		.link {
			font-size: 30px;
			font-weight: 400;
			font-family: $font-family-tertiary;

			@include media-breakpoint-up(lg) {
				font-size: 40px;
				padding: 16px 40px;
			}
		}

		&::before {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: 832px;
			height: 658px;
			background: url('/images/shrek/sparkles-intro.png');
			animation: sparkles-1 60s linear infinite;
		}

		@keyframes sparkles-1 {

			0%,
			100% {
				transform: translateY(-50%);
			}

			50% {
				transform: translateY(0%);
			}
		}
	}

	// shrek-featured-section
	// =========================================================================
	.shrek-featured-section {
		position: relative;
		margin-bottom: 32px;

		@include media-breakpoint-up(xl) {
			margin-bottom: 55px;
		}

		padding-top: 6vh;
		padding-bottom: 6vh;
		background: $cream;
		box-shadow: 0px -25px 20px -20px rgba($black, 0.15) inset,
		0px 25px 20px -20px rgba($black, 0.5) inset;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: calc(100% - 30px);
			height: 177px;
			background: url('/images/shrek/picture-frame-top.png') no-repeat center;

			@include media-breakpoint-down(lg) {
				bottom: calc(100% - 20px);
				height: 106px;
				background-size: cover;
			}
		}

		&::after {
			content: "";
			position: absolute;
			top: calc(100% - 3px);
			left: 0;
			right: 0;
			height: 65px;
			background: url('/images/shrek/picture-frame-bottom.png') no-repeat center;

			@include media-breakpoint-down(lg) {
				height: 39px;
				background-size: cover;
			}
		}

		h1,
		h2 {
			color: $gold;
		}

		.shrek_detail_map {
			.w3-vanhoorne-ntk-widget {
				text-align: initial !important;

				.w3-filtering .filtering-element .form-control {
					background: $white !important;
				}

				.btn {
					padding: 0.65rem 0.75rem !important;
					border-radius: 22px !important;
					font-size: 16px !important;
				}
			}
		}
	}

	// shrek-media-section
	// =========================================================================
	.shrek-media-section {
		padding: 6vh 15px;
		background: $black;
		color: $white;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $white;
		}

		.container {
			max-width: 1366px;

			@include media-breakpoint-down(xs) {
				padding-left: 15px !important;
				padding-right: 15px !important;
			}
		}

		.owl-carousel {
			&.image_collection {
				max-width: 940px;
				min-height: auto;
				margin: 0 auto;

				.item {
					height: 75vh !important; // override
					min-height: 360px; // override
					max-height: 520px;
					padding: 0; // override

					@include media-breakpoint-down(xs) {
						height: auto !important;
						min-height: auto;
						max-height: none;
						background-size: contain !important;
						aspect-ratio: 20 / 13;
					}
				}

				.youtube-layer {
					&::before {
						content: '\f144';
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						display: flex;
						align-items: center;
						justify-content: center;
						color: $white;
						font-size: 100px;
						font-family: $font-awesome;
						transition: 0.5s;
					}

					&:hover {
						&::before {
							color: $green;
						}
					}
				}

				.owl-nav {

					.owl-prev,
					.owl-next {
						width: 40px;
						height: 40px;

						@include media-breakpoint-up(md) {
							width: 50px;
							height: 50px;
						}

						@include media-breakpoint-up(xl) {
							width: 75px;
							height: 75px;
						}

						@media (min-width: 1366px) {
							width: 100px;
							height: 100px;
						}

						img {
							max-width: 100%;
							height: auto;
						}
					}

					.owl-prev {
						margin-left: 15px;

						@include media-breakpoint-up(xl) {
							margin-left: -100px;
						}

						@media (min-width: 1366px) {
							margin-left: -140px;
						}

						&:hover {
							transform: translateX(-3px);
						}
					}

					.owl-next {
						margin-right: 15px;

						@include media-breakpoint-up(xl) {
							margin-right: -110px;
						}

						@media (min-width: 1366px) {
							margin-left: -140px;
						}

						&:hover {
							transform: translateX(3px);
						}
					}
				}
			}

			// thumbs
			&.owl-thumbs {
				margin-top: 40px;
				min-height: auto;

				.item {
					border: 2px solid transparent;
					height: 100px !important; // override
					min-height: auto; // override
					padding: 0; // override
					transition: border 0.5s;

					.owl-caption-holder {
						display: none;
					}

					&:hover {
						border-color: $white;
						cursor: pointer;
					}
				}

				.owl-item {
					&.current {
						.item {
							border-color: $white;
						}
					}
				}
			}
		}
	}

	// shrek-information-section
	// =========================================================================
	.shrek-information-section {
		padding: 70px 0 120px 0;
		background: url('/images/shrek/information-bg.jpg') no-repeat bottom $black;
		background-size: cover;
		text-align: center;

		@include media-breakpoint-up(lg) {
			padding-top: 110px;
		}

		@include media-breakpoint-up(xl) {
			padding-bottom: 200px;
		}
	}

	// shrek-cast-section
	// =========================================================================
	.shrek-cast-section {
		position: relative;
		padding: 6vh 0;
		background: url('/images/shrek/cast-bg.jpg') no-repeat $black;
		background-size: cover;
		color: $white;
		text-align: center;
		overflow: hidden;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 347px;
			height: 1036px;
			background: url('/images/shrek/sparkles-cast-left.png');
			animation: sparkles-2 60s linear infinite;
		}

		&::after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: 271px;
			height: 575px;
			background: url('/images/shrek/sparkles-cast-right.png');
			animation: sparkles-3 60s linear infinite;
		}

		@keyframes sparkles-2 {

			0%,
			100% {
				transform: translateY(0%);
			}

			50% {
				transform: translateY(-50%);
			}
		}

		@keyframes sparkles-3 {

			0%,
			100% {
				transform: translateY(-50%);
			}

			50% {
				transform: translateY(0%);
			}
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $white;
		}

		.contentblock {

			&.owl-carousel {
				min-height: 0;
				padding: 0;

				.item {
					min-height: 0;
					padding: 0;

					.card {
						background: none;
						border: none;
						border-radius: 0;
						text-decoration: none;

						.card-image {
							margin-bottom: 15px;
							background: none;
							border-radius: 100%;
							aspect-ratio: 1;
							overflow: hidden;

							img {
								height: 100%;
								object-fit: cover;
								transition: 0.5s;
							}

							&:hover {
								img {
									transform: scale(1.05);
								}
							}
						}

						.card-body {
							padding: 0;
							background: none;

							.card-caption {

								.card-title,
								.card-subtitle {
									margin-bottom: 0.25rem;
									color: $white;
									font-size: 17px;
									font-weight: 500;
									text-align: center;
								}
							}

							.card-buttons {
								display: none;
							}
						}
					}
				}

				.owl-dots {
					.owl-dot {
						span {
							border-color: $white;
						}

						&:hover {
							span {
								background: $white;
							}
						}

						&.active {
							span {
								background: $white;
								border-color: $white;
							}
						}
					}
				}
			}
		}

		.container-ears {
			margin-top: 65px;

			@include media-breakpoint-up(lg) {
				margin-top: 95px;
			}

			.container-holder {
				padding-bottom: 30px;

				@include media-breakpoint-up(xl) {
					padding: 60px 60px 45px 60px;
				}
			}
		}
	}

	// shrek-social-section
	// =========================================================================
	.shrek-social-section {
		padding: 35px 0;
		background: $green-dark;
		color: $white;
		font-size: 36px;
		font-weight: 600;
		line-height: 1;
		text-align: center;
		font-family: $font-family-secondary;

		@include media-breakpoint-up(xl) {
			font-size: 48px;
		}

		p {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			gap: 15px;
			margin-bottom: 0;

			strong {
				margin-bottom: -10px;
				font-weight: 600;
			}

			a {
				@extend .btn;
				padding: 16px 22px;
				background-color: $white;
				border-color: $white;
				color: $green-dark;
				font-size: 30px;
				font-family: $font-family-primary;

				@include media-breakpoint-up(xl) {
					font-size: 40px;
				}

				&:hover {
					background-color: $green;
					border-color: $green;
					color: $green-dark;
				}
			}
		}
	}

	// shrek-shop-bg
	// =========================================================================
	.shrek-shop-bg {
		position: relative;
		background: url('/images/shrek/shop-bg.jpg') no-repeat bottom $black;
		background-size: cover;
		overflow: hidden;

		&::before {
			content: '';
			position: absolute;
			top: 75%;
			left: 0;
			width: 476px;
			height: 1094px;
			background: url('/images/shrek/sparkles-shop-left.png');
			animation: sparkles-4 60s linear infinite;
		}

		&::after {
			content: '';
			position: absolute;
			top: 25%;
			right: 0;
			width: 282px;
			height: 702px;
			background: url('/images/shrek/sparkles-shop-right.png');
			animation: sparkles-5 60s linear infinite;
		}

		@keyframes sparkles-4 {

			0%,
			100% {
				transform: translateY(0%);
			}

			50% {
				transform: translateY(-50%);
			}
		}

		@keyframes sparkles-5 {

			0%,
			100% {
				transform: translateY(-50%);
			}

			50% {
				transform: translateY(0%);
			}
		}
	}

	// shrek-shop-section
	// =========================================================================
	.shrek-shop-section {
		padding: 6vh 0;
		color: $white;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $white;
		}
	}

	// shrek-news-section
	// =========================================================================
	.shrek-news-section {
		position: relative;
		padding: 6vh 0;
		color: $white;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			max-width: 1140px;
			height: 2px;
			margin: 0 auto;
			background: $green;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $white;
		}

		.contentblock {
			&.owl-carousel {
				min-height: 0;
				padding: 0;

				.item {
					min-height: 0;
					padding: 0;
				}

				.owl-dots {
					.owl-dot {
						span {
							border-color: $white;
						}

						&:hover {
							span {
								background: $white;
							}
						}

						&.active {
							span {
								background: $white;
								border-color: $white;
							}
						}
					}
				}
			}

			.card {
				background: none;
				border: none;
				border-radius: 0;

				.card-image {
					margin-bottom: 20px;
					background: $white;
				}

				.card-body {
					padding: 0;
					background: none;

					.card-caption {
						display: flex;
						flex-direction: column;
						flex: 0;
						color: $white;

						.card-title,
						.card-title-link,
						.card-subtitle,
						.card-description {
							color: inherit;
						}

						.card-title {
							font-size: 26px;
							line-height: 1.5;
						}

						.card-subtitle,
						.card-description {
							font-size: 16px;
							font-weight: 500;
						}

						.card-subtitle {
							order: -1;
						}

						.card-description {
							&::after {
								display: none !important;
							}
						}
					}

					.card-buttons {
						margin-top: 0;

						.card-btn {
							padding: 0;
							background: none;
							border-radius: 0;
							border: none;
							border-bottom: 1px solid $white;
							color: $white;
							font-size: 16px;
							font-weight: 500;

							&::after {
								content: '...';
							}
						}
					}
				}
			}
		}
	}

	// shrek-logo-section
	// =========================================================================
	.shrek-logo-section {
		padding: 6vh 0;
		text-align: center;
	}

	// shrek-reviews-section
	// =========================================================================
	.shrek-reviews-section {
		padding-top: 6vh;
		padding-bottom: calc(6vh + 90px);
		background: url('/images/shrek/footer-bg.png') no-repeat center bottom $green-dark;
		background-size: 100%;
		color: $white;
		font-size: 18px;
		font-weight: 400;
		text-align: center;

		h4 {
			margin-bottom: 20px;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $white;
		}
	}

	// shrek-footer
	// =========================================================================
	.shrek-footer {
		position: relative;
		padding: 6vh 0 30px 0;
		font-size: 18px;
		font-weight: 400;
		text-align: center;

		.container {
			max-width: 850px;
		}

		a {
			color: $green-dark;

			&:hover {
				text-decoration: underline;
			}
		}

		// footer-outro
		.footer-outro {}

		// footer-logo
		.footer-logo {
			margin: 6vh 0;
		}

		// footer-socials
		.footer-socials {
			margin-bottom: 45px;

			ul {
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0;
				padding: 0;
				list-style: none;

				li {
					margin: 0 10px;
				}
			}
		}

		// footer-copyright
		.footer-copyright {
			margin: 30px 0;

			a {
				color: $black;
			}
		}
	}
}
