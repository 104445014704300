.card {
    background: transparent !important;
    border: none;
    overflow: visible;

    &-image {
        box-shadow: 3px 3px 6px rgba($black, 0.16);
        border-radius: 8px;
        border: 1px solid rgba($white, 0.5);
        background: transparent !important;
        margin-bottom: 5px;
        .card-img {
            border-radius: 8px !important;
        }
    }
    &-body {
        background: transparent !important;

        .card-caption {
            .card-title-link {
                .card-title {
                    font-size: $font-size-base * 1.5;

                    @include media-breakpoint-up(sm) {
                        font-size: $font-size-base * 1.875;
                    }
                }
            }
            .card-subtitle {
                font-size: $font-size-base * 1.125;

                @include media-breakpoint-up(sm) {
                    font-size: $font-size-base * 1.25;
                }
            }
            .card-description {
                min-height: min-content;
                max-height: max-content;

                &::after {
                    content: none;
                }
            }
        }
        .card-buttons {
            .card-btn {
                @extend .btn-highlight;
            }
        }
    }
}
.grid.show_overview,
.content-blocks {
    .card {
        width: 100%;
        border: none;
        border-radius: 0;

        &-image {
            &-link {
                overflow: hidden;
                border-radius: 8px;

                &:hover {
                    .card-img {}
                }
                .card-img {
                    border-radius: 8px !important; // !important needed to override base css
                    width: 100%;
                    height: auto;
                    transition: all 0.5s ease-in-out;
                }
            }
        }
        &-body {
            padding: 30px 0 !important; // !important needed to override base css

            .card-caption {
                display: flex;
                flex-direction: column;
                max-width: 400px;
                flex: 0;

                @include media-breakpoint-up(sm) {
                    flex: 1 1 auto;
                }
                .card-title-link {
                    order: 2;

                    &:hover {
                        .card-title {
                            color: $base-1;
                        }
                    }
                    .card-title {
                        color: $primary;
                    }
                }
                .card-subtitle {
                    order: 1;
                    margin-bottom: 10px;
                }
                .card-description {
                    order: 3;
                    margin-top: 10px;
                    font-weight: 500;

                    @include media-breakpoint-up(sm) {
                        margin-top: 25px;
                    }
                    &-content {
                        p {
                            font-size: $font-size-base_sm;
                        }
                    }
                }
            }
        }
    }
}

//Slider card

.home-blocks,
.contact-intro,
.park-contentblocks {
    .slider {
        padding-right: 5px;
        .card {
            border: none;
            background: transparent;

            &-image {
               height: max-content;

                &-link {
                    border-radius: 8px;

                    .card-img {
                        border-radius: 8px !important; // !important needed to override base css
                    }
                }
            }
            &-body {
                background: $primary;

                .card-caption {
                    .card-title-link {
                        color: $white;
                    }
                    .card-subtitle {
                        font-size: $font-size-base * 1.25;
                        color: $white;
                    }
                    .card-description {
                        margin-top: 40px;

                        &-content {
                            .info-extra-list {
                                @extend .info-list;
                            }
                        }
                    }
                }
            }
        }
    }
}
.contact-intro,.park-contentblocks {
    .card-body {
        padding: 15px 0;

        .card-caption {
            .card-title-link {
                &:hover {
                    .card-title {
                        color: $base-1;
                    }
                }
            }
            .card-description {
                margin-top: 20px !important;  // !important needed to override base css

                &-content {
                    .info-extra-list {
                        @extend .info-list;

                        &-item {
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }

}
