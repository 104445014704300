/* bootstrap > theme-colors
   ========================================================================== */
$primary: #76bf43 ;
$secondary: #00b5e9 ;

/* bootstrap > font
   ========================================================================== */
@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;700&display=swap");

$font-family-primary: 'Be Vietnam Pro', sans-serif;
$font-awesome: 'Font Awesome 5 Pro';

$font-family-base: $font-family-primary;

$font-size-base: 1rem; //16px
$font-size-lg: 1.125rem; //18px
$font-size-base_sm: 0.875rem; //14px
$font-size-base_xs: 0.813rem; //13px
$font-size-base_xxs: 0.75rem; //12px

$h1-font-size: $font-size-base * 3;
$h2-font-size: $font-size-base * 2.25;

body:not(.shrek_page):not(.thewiz_page) {
	margin: 5px;
	overflow-x: hidden;
 }
