// variables
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&display=swap');
$font-family-secondary: 'Cinzel', serif;

$blue: #4276a7;
$blue-dark: #0c276b;
$brown: #6a2808;
$orange: #ff8316;
$orange-light: #ffac39;
$cream: #ffffe2;

&.thewiz_page {
	background-image: url('/images/thewiz/body-bg-left.png'), url('/images/thewiz/body-bg-right.png');
	background-position: top left, top right;
	background-repeat: repeat-y;
	background-color: $blue;
	color: $black;
	font-weight: 400;

	@media (max-width: 1363px) {
		background-size: 50%;
	}

	@include media-breakpoint-up(lg) {
		font-size: 18px;
	}

	a {
		color: $orange;

		&:hover {
			color: darken($orange, 50%);
		}
	}

	// headings
	h1,
	h2 {
		margin-bottom: 1rem;
		color: $brown;
		font-size: calc(1.325rem + .9vw);
		font-weight: 700;
		text-transform: uppercase;
		font-family: $font-family-secondary;

		@include media-breakpoint-up(xl) {
			font-size: 38px;
		}
	}

	h4,
	h5,
	h6 {
		font-weight: 700;
	}

	h3 {
		font-size: 24px;

	}

	h4 {
		font-size: 22px;
	}

	p {
		margin-bottom: 1.5rem;
	}

	// hr
	hr {
		display: block;
		max-width: 816px;
		height: 38px;
		margin: 30px auto;
		border: 0;
		background: url('/images/thewiz/hr-line.png') no-repeat center;
		overflow: hidden;
	}

	// btn
	.btn {
		padding: 16px 20px;
		border-radius: 15px;
		font-size: 18px;
		font-weight: 700;
		line-height: 1;
		transition: 0.25s;

		@include media-breakpoint-up(xl) {
			padding: 18px 25px;
			font-size: 22px;
		}

		&.btn-primary {
			background-color: $orange;
			border-color: $orange;
			color: $white;

			&:hover {
				background-color: $brown;
				border-color: $brown;
				color: $white;
			}
		}
	}

	header.header.sticky {
		@include media-breakpoint-down(lg) {
			position: relative !important;
		}
	}

	// containers
	.container-medium {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}
	}

	.container-large {
		@include media-breakpoint-up(lg) {
			max-width: 960px;
		}
	}

	.container-intro {
		@media (min-width: 850px) {
			max-width: 820px;
		}

		margin-bottom: 30px;
		text-align: center;
	}

	.container-outro {
		@media (min-width: 850px) {
			max-width: 820px;
		}

		margin-top: 30px;
		text-align: center;
	}

	// fancybox
	.fancybox-card-title {
		font-size: 24px;
		font-weight: 600;
	}

	.fancybox-card-subtitle {
		font-size: 18px;
		font-weight: 500;
	}

	.fancybox-card-description {
		font-size: 16px;
	}

	// thewiz-intro-section
	// =========================================================================
	.thewiz-intro-section {
		position: relative;
		padding: 30px 0 8vw 0;
		background-image: url('/images/thewiz/header-bottom.png'), url('/images/thewiz/header-bg.jpg');
		background-position: bottom center, top center;
		background-repeat: no-repeat;
		background-size: contain, cover;
		text-align: center;
		overflow: hidden;

		@include media-breakpoint-up(xl) {
			padding-top: 155px;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $white;
		}

		h1,
		h2 {
			background: url('/images/thewiz/sparkle.png') no-repeat center top;

			@include media-breakpoint-down(lg) {
				background-size: contain;
			}

			@include media-breakpoint-up(xl) {
				min-width: 423px;
				min-height: 85px;
			}
		}

		// logo
		.logo {
			margin-bottom: 15px;

			@include media-breakpoint-down(lg) {
				img {
					max-width: 65%;
				}
			}
		}

		// link
		.link {
			border-radius: 10px;
			padding: 18px 25px;
			font-size: 24px;
			font-weight: 900;

			@include media-breakpoint-up(xl) {
				font-size: 36px;
				padding: 23px 35px;
			}
		}
	}

	// thewiz-trailer-section
	// =========================================================================
	.thewiz-trailer-section {
		background: $black;

		iframe {
			border-radius: 0;
		}
	}

	// thewiz-cast-section
	// =========================================================================
	.thewiz-cast-section {
		position: relative;
		padding: 6vh 0;
		color: $white;
		text-align: center;
		overflow: hidden;

		.container-slider {
			max-width: 1388px !important;
			padding: 0 !important;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $white;
		}

		// contentblock
		.contentblock {
			&.owl-carousel {
				min-height: 0;
				padding: 0;

				.item {
					min-height: 0;
					padding: 0;

					.card {
						width: 347px;
						background: none;
						border: none;
						border-radius: 0;
						text-decoration: none;
						overflow: visible;

						.card-image {
							position: relative;
							width: 347px;
							height: 251px;
							background: none;
							margin-bottom: 25px;
							aspect-ratio: 1;
							overflow: hidden;

							picture {
								position: relative;
								display: block;
								height: 100%;

								&::after {
									content: '';
									position: absolute;
									top: 0;
									left: 0;
									right: 0;
									bottom: 0;
									background: url('/images/thewiz/kader.png');
									background-size: cover;
								}
							}

							img {
								padding: 5px 52px;
								height: 100%;
								object-fit: cover;
								transition: 0.5s;
							}

							&:hover {
								img {
									transform: scale(1.01);
								}
							}
						}

						.card-body {
							padding: 0;
							background: none;

							.card-caption {

								.card-title,
								.card-subtitle {
									margin-bottom: 0.25rem;
									color: $white;
									font-size: 16px;
									text-align: center;
								}

								.card-title {
									font-weight: 700;
								}

								.card-subtitle {
									font-weight: 400;
								}
							}

							.card-buttons {
								display: none;
							}
						}
					}
				}

				// owl-dots
				.owl-dots {
					margin: 15px 30px 0 30px;

					.owl-dot {
						span {
							border-color: $white;
						}

						&:hover {
							span {
								background: $white;
							}
						}

						&.active {
							span {
								background: $white;
								border-color: $white;
							}
						}
					}
				}
			}
		}
	}

	// thewiz-information-section
	// =========================================================================
	.thewiz-information-section {
		padding: 6vh 0;
		background: $cream;
		color: $brown;
		font-size: 16px;
		font-weight: 700;
		text-align: center;
	}

	// thewiz-dates-section
	// =========================================================================
	.thewiz-dates-section {
		padding: 6vh 0;
		color: $white;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $white;
		}

		// events
		.custom_detail_events {
			.TopText {
				display: none;
			}

			.ntk-events {
				.ntk-event-item {
					position: relative;
					display: flex;
					flex-flow: row wrap;
					align-items: center;
					column-gap: 30px;
					margin-bottom: 30px;
					padding: 10px 30px 10px 10px;
					border-radius: 15px;
					background-color: $cream;

					@include media-breakpoint-down(sm) {
						padding-right: 10px;
						row-gap: 15px;
						column-gap: 15px;
					}

					&:last-of-type {
						margin-bottom: 0;
					}

					.ntk-event-item-label {
						position: absolute;
						right: 30px;
						bottom: calc(100% - 5px);
						background-color: $orange;
						border-radius: 5px;
						padding: 5px 10px;
						font-size: 14px;
					}

					.ntk-event-item-date {
						width: 100px;
						background: $blue-dark;
						border-radius: 10px;
						padding: 10px;
						text-align: center;
					}

					.ntk-event-item-info {
						flex: 1;
						color: $black;

						.ntk-event-item-info-city {
							font-size: 24px;
							text-transform: uppercase;
						}
					}

					.ntk-event-item-go {
						display: flex;
						flex-wrap: wrap;
						flex-direction: row-reverse;
						align-items: center;
						column-gap: 15px;

						@include media-breakpoint-down(sm) {
							width: 100%;
							justify-content: flex-end;
						}

						a {
							color: $black;
							font-weight: 700;
							text-decoration: underline;

							i {
								display: none;
							}

							&:last-of-type {
								@extend .btn;
								@extend .btn-primary;
								padding: 15px !important;
								font-size: 18px !important;
								font-weight: 400 !important;
								text-decoration: none;
							}
						}
					}
				}
			}

			.js-show-extra-events {
				display: none;
			}
		}
	}

	// thewiz-photos-section
	// =========================================================================
	.thewiz-photos-section {
		padding: 6vh 0;
		background: $cream;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $brown;
		}

		// image_collection
		.owl-carousel {
			&.image_collection {
				min-height: auto;

				.item {
					height: 75vh !important; // override
					min-height: 300px; // override
					max-height: 520px;
					padding: 0; // override
				}

				// owl-dots
				.owl-dots {
					position: static;
					margin-top: 15px;

					.owl-dot {
						span {
							border-color: $orange;
							box-shadow: none;
						}

						&:hover {
							span {
								background: $orange;
							}
						}

						&.active {
							span {
								background: $orange;
								border-color: $orange;
							}
						}
					}
				}


				// owl-nav
				.owl-nav {
					display: none;
				}
			}
		}
	}

	// thewiz-story-section
	// =========================================================================
	.thewiz-story-section {
		padding: 6vh 0 0 0;
		color: $white;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $white;
		}

		// character
		.container-character {
			@media (min-width: 862px) {
				max-width: 832px;
			}

			h2 {
				background: none;

				@include media-breakpoint-up(xl) {
					min-width: auto;
					min-height: auto;
				}
			}

			.container-holder {
				align-items: center;

				.column {
					&.one {
						padding-right: 15px !important;
						text-align: right;
					}

					&.two {
						padding-left: 15px !important;
					}
				}
			}
		}
	}

	// thewiz-news-section
	// =========================================================================
	.thewiz-news-section {
		padding: 0 0 6vh 0;
		color: $white;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $white;
		}

		.contentblock {
			&.owl-carousel {
				min-height: 0;
				padding: 0;

				.item {
					min-height: 0;
					padding: 0;
				}

				.owl-dots {
					.owl-dot {
						span {
							border-color: $white;
						}

						&:hover {
							span {
								background: $white;
							}
						}

						&.active {
							span {
								background: $white;
								border-color: $white;
							}
						}
					}
				}
			}

			.card {
				background: $cream;
				border: none;
				border-radius: 0;

				.card-image {
					background: $black;
				}

				.card-body {
					padding: 15px;
					background: none;

					@include media-breakpoint-up(xl) {
						padding: 30px;
					}

					.card-caption {
						display: flex;
						flex-direction: column;
						flex: 0;
						color: $brown;

						.card-title,
						.card-title-link,
						.card-subtitle,
						.card-description {
							color: inherit;
						}

						.card-title {
							font-size: 24px;
						}

						.card-subtitle,
						.card-description {
							font-size: 18px;
							font-weight: 400;
						}

						.card-subtitle {
							order: -1;
						}

						.card-description {
							display: none;

							&::after {
								display: none !important;
							}
						}
					}

					.card-buttons {
						margin-top: 0;

						.card-btn {
							padding: 0;
							background: none;
							border-radius: 0;
							border: none;
							color: $brown;
							font-size: 18px;
							font-weight: 700;
						}
					}
				}
			}
		}
	}

	// thewiz-newsletter-section
	// =========================================================================
	.thewiz-newsletter-section {
		padding: 6vh 0;
		background: $cream;

		.container {
			.container-holder {

				.column {
					&.one {
						@include media-breakpoint-up(md) {
							@include make-col(7);
						}

						h2 {
							@include media-breakpoint-up(xl) {
								font-size: 48px;
							}
						}

						.resource_image {
							margin-bottom: -6vh;

							@include media-breakpoint-up(xl) {
								margin-left: -100px;
							}
						}
					}

					&.two {
						@include media-breakpoint-up(md) {
							@include make-col(5);
						}

					}
				}
			}
		}
	}

	// thewiz-reviews-section
	// =========================================================================
	.thewiz-reviews-section {
		padding-top: 6vh;
		color: $white;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $white;
		}

		// contentblock
		.contentblock {
			&.owl-carousel {
				min-height: 0;
				padding: 0;

				.item {
					min-height: 0;
					padding: 0;

					.card {
						border-radius: 15px;
						border: none;
						background-color: $orange-light;

						.card-image {
							display: none;
						}

						.card-body {
							background: none;
							padding: 15px;

							@include media-breakpoint-up(xl) {
								padding: 25px;
							}

							.card-caption {
								display: flex;
								flex-direction: column;
								color: $white;
								text-align: center;

								.card-title {
									margin-bottom: 0;
									color: $white;
									text-align: center;
									font-family: $font-family-secondary;
								}

								.card-description {
									order: -1;
									min-height: auto;
									max-height: none;
									color: $white;
									font-size: 22px;
									font-weight: 900;
									line-height: 1;
									text-align: center;

									@include media-breakpoint-up(xl) {
										font-size: 26px;
									}

									img {
										width: auto;
										margin: 0 auto;
									}

									&::after {
										display: none !important;
									}
								}
							}

							.card-buttons {
								display: none;
							}
						}
					}
				}

				// owl-dots
				.owl-dots {
					.owl-dot {
						span {
							border-color: $white;
						}

						&:hover {
							span {
								background: $white;
							}
						}

						&.active {
							span {
								background: $white;
								border-color: $white;
							}
						}
					}
				}
			}
		}
	}

	// thewiz-footer
	// =========================================================================
	.thewiz-footer {
		color: $white;
		text-align: center;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $white;
		}

		// socials
		.socials {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				margin: 0 10px;

				a {
					display: block;
					width: 50px;
					border-radius: 100%;
					background-color: $white;
					color: $blue;
					font-size: 26px;
					text-align: center;
					line-height: 50px;
					transition: 0.5s;

					span {
						display: none;
					}

					&:hover {
						background-color: $orange;
						color: $white;
					}
				}
			}
		}

		// logo
		.logo {
			margin-bottom: 15px;

			@include media-breakpoint-down(lg) {
				img {
					max-width: 65%;
				}
			}
		}
	}

	// footer
	// =========================================================================
	.footer {
		margin-top: 30px;

		.image {
			img {
				display: block;
				width: 100%;
			}
		}

		.footer-section {
			background: $white;
			padding: 0 0 30px 0;
			color: $black;
			text-align: center;

			@include media-breakpoint-down(md) {
				padding: 15px;
			}

			a {
				color: $black;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}