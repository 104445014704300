@import './0_config/vendor-variables';
@import '~framework/assets/scss/Dev/0_config/vendor-variables';
@import '~framework/assets/scss/Dev/setup';
@import './0_config/website-variables';
@import '~framework/assets/scss/Dev/0_config/website-variables';

@import '~framework/assets/scss/Dev/1_vendor/bootstrap';

// not shrek
body:not(.shrek_page):not(.thewiz_page) {
	@import '~framework/assets/scss/Dev/base';

	@import './2_layout/header';
	@import './2_layout/sections';
	@import './2_layout/base';
	@import './2_layout/eyecatcher';
	@import './2_layout/footer';
	@import './2_layout/ticket_overview';

	@import './3_components/button';
	@import './3_components/owl-carousel';
	@import './3_components/card';
	@import './3_components/contentblock';
	@import './3_components/ntk-events';
	@import './3_components/article-odd-even';
	@import './3_components/fancybox';

	@import './6_theme/error';
	@import './6_theme/show';
	@import './6_theme/page.show_location_overview';
	@import './6_theme/page.show_overview';
	@import './6_theme/page.vacancy_overview';
	@import './6_theme/page.show_detail';
}

// shrek
body.shrek_page {
	@import '~framework/assets/scss/Dev/base';

	@import './2_layout/header';

	@import './3_components/fancybox';

	@import './6_theme/shrek';
}

// the wiz
body.thewiz_page {
	@import '~framework/assets/scss/Dev/base';

	@import './2_layout/header';

	@import './3_components/fancybox';

	@import './6_theme/thewiz';
}


html[lang='de'] {
	body {
		.logo:not(.de-only) {
			display: none !important;
		}
	}
}

html:not([lang='de']) {
	body {
		.logo.de-only {
			display: none !important;
		}
	}
}
