.ntk-events {
	@include make-row();

	.ntk-events-list-item {
		@include make-col-ready();
		@include make-col(12);

		@include media-breakpoint-up(lg) {
			@include make-col(4);
		}

		.ntk-events-list-item-info {
			font-size: 1.1em;
			font-weight: bold;

			a {
				color: #000000;

				.ntk-events-list-item-info-date {}
				.ntk-events-list-item-info-icon {
					margin-left: 6px;
				}
			}
		}

		.ntk-events-list-item-title {
			margin-top: 3px;
		}

		margin-bottom: 30px;

		a {
			color: #00B5E9;
		}

		a.sold-out {
			color: #E41E26;
		}

		.ntk-event-label {
			color: #76BF43;
			font-size: 14px;
			font-weight: 700;
		}

	}
}
