&.show_ticket_overview {
	.intro-section {
		margin-top: 75px;
		position: relative;
		@media(max-width: 767px) {
			padding-bottom: 25px;
		}

		h1, h2 {
			text-align: center;
		}

		&::before {
			background: url("/images/curve-green-transparent.svg") no-repeat top/100%;
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			top: calc(100% - 1px);
			right: 0;
			z-index: 11;
		}

		+ .content {
			z-index: 100;
			position: relative;
			padding-top: 0;

			.filter {
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;
				margin-bottom: 15vh;
				@media(max-width: 767px) {
					margin-bottom: 15px;
				}
			}

			.ntk-events-list-item {

			}

			#show-select,
			#province-select,
			#place-select {
				height: calc(1.5em + 0.75rem + 11px);
				color: #231F20;
				box-shadow: 0 3px 6px rgba(0, 0, 0, .16) !important;
				padding: 0.375rem 0.75rem;
				background-color: #FFFFFF;
				border: 1px solid #CED4DA;
				border-radius: 0.25rem;
				font-size: 1rem;
				font-style: normal;
				font-weight: 400;
				line-height: 1.5;
				width: calc(33.333% - 10px);
				max-width: calc(33.333% - 10px);
				@media(max-width: 767px) {
					width: 100%;
					max-width: 100%;
					margin-bottom: 15px;
				}
			}
		}
	}
}
