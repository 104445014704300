.btn-highlight {
	display: inline-flex;
	align-items: center;
	padding: 10px 40px;
    font-size: $font-size-base_xxs;
    font-weight: 700;
    border: 1px solid $base-1;
	border-radius: 22px;
    color: $white;
	background: $base-1;
    text-transform: uppercase;
	text-decoration: none !important;
	transition: 0.3s;

    @include media-breakpoint-up(sm) {
        font-size: $font-size-base_sm;
    }
	&:hover,
	&:focus,
    &:active  {
		color: $base-1 !important;
		background: $white;
    }
}
.btn-primary {
	display: inline-flex;
	align-items: center;
	padding: 10px 40px;
    font-size: $font-size-base_xxs;
    font-weight: 700;
	color: $white;
	border-radius: 22px;
	text-transform: uppercase;
	text-decoration: none;
	transition: 0.3s;
	background-color: $base-1;
	border-color: $base-1;

	@include media-breakpoint-up(sm) {
        font-size: $font-size-base_sm;
    }
	&:hover,
	&:focus,
    &:active  {
		color: $base-1;
		background: $white;
    }
}