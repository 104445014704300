.footer {
    padding: 0;

	.brands-section  {
		padding: 6vh 0;
	}

    .footer-top {
        background: $primary;
        padding: 70px 0;

        @include media-breakpoint-up(sm) {
            padding: 90px 0;
        }

        .container-default {
            @extend .container;

            .container-holder {
                .page_block.footer_text {
                    color: $white;

                    h1,
                    h2 {
                        color: $white;
                    }
                    h2 {
                        margin-left: 5px;
                    }
                    p {
                        font-size: $font-size-base;
                        font-weight: 500;
                        margin-bottom: 30px;
                    }
                    ul {
                        @extend .list-item;
                    }
                }
            }
            &:last-of-type {
                //max-width: 719px;
                margin-top: 20px;
            }
        }
    }

    .footer-socket {
        background: $white;
        padding: 60px 0;

        .container-holder {
            margin: auto;
            text-align: center;

            .page_block {
                display: flex;
                justify-content: center;

                &.logo {
                    max-width: 218px;
                    margin: auto auto 58px auto;

                    img {
                        height: 100px;    
                    }
                }
                &.footer_logolink {
                    .list {
                        margin-bottom: 24px;

                        .list-item {
                            .link {
                                color: $secondary;

                                i {
                                    font-size: $font-size-base * 1.875;
                                }
                                &:hover {
                                    opacity: 0.7;
                                }
                            }
                            &:not(:last-child) {
                                margin-right: 20px;
                            }
                        }
                    }
                }
                &.footer_link {
                    .list {
                        display: flex !important; // !important needed to override base css
                        padding-left: 30px;

                        @include media-breakpoint-up(sm) {
                            padding-left: 0;
                        }
                        .list-item {
                            margin-right: 10px;
                            margin-bottom: 5px;
                            font-weight: 500;
                            font-size: $font-size-base / 1.6;

                            @include media-breakpoint-up(sm) {
                                font-size: $font-size-base_xxs;
                                margin-right: 26px;
                            }
                            .link {
                                color: $base-1;
                                text-decoration: none;

                                &:hover {
                                    color: $primary;
                                }
                            }
                        }
                    }
                }
                &.footer_text {
                    p {
                        font-size: $font-size-base / 1.6;
                        font-weight: 300;

                        @include media-breakpoint-up(sm) {
                            font-size: $font-size-base_xxs;
                        }
                    }
                }
            }
        }
    }

    .footer-newsletter {
        .container-default {
            .container-holder {
                .page_block.app_newsletter {
                    .btn {
                        position: fixed;
                        right: 20px;
                        bottom: 10px;
                        z-index: 20;
                        font-size: $font-size-base_sm;
                        font-weight: 500;
                        color: $secondary;
                        background: $white;
                        padding: 4px 15px;
                        box-shadow: 3px 3px 6px rgba($black, 0.16);

                        @include media-breakpoint-up(sm) {
                            right: 100px;
                        }
                        i {
                            margin-right: 2px;
                        }
                    }
                }
            }
        }
    }
}
