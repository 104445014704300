$body-padding: 0;
$body-sticky-padding: 0;

/* Custom theme-colors
   ========================================================================== */

$base-1 : #231F20;
$base-2 : #acd890;
$base-3 : #A8A8A8;
$base-4:  #C4C4C4;

/* ==========================================================================
   owl-carousel
   ========================================================================== */
   $carousel-min_height: 90vh !default;
   $carousel-min_height_lg: 100vh !default;
