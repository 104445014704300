.eyecatcher {
	&.small {
		min-height: 0 !important;
		height: 75vh !important;
		max-height: 540px !important;
		.owl-carousel {
			min-height: 0 !important;
			.item {
				min-height: 0 !important;
				height: 75vh !important;
				max-height: 540px !important;
			}
		}
	}
}

.eyecatcher {
    .container-default { max-width: none;}
    &::before {
        content: none;
    }
    &.large {
        min-height: $carousel-min_height;

        @include media-breakpoint-up(lg) {
            min-height: $carousel-min_height_lg;
        }
    }
    iframe {
        display: none;
        @include media-breakpoint-up(md) {
            display: block;
        }
    }
    .owl-carousel {
        &::before {
            content: none;
            border: none;
        }
        .owl-stage-outer {
            .owl-stage {
                .owl-item {
                    .item {
                        min-height:calc(100vh - 10px);
                    }
                }
            }
        }
        .owl-dots {
            height: auto;
        }
    }
    .page_block.app_scroll_down {
        display: none;
        bottom: 30px;
        left: 0;
        margin-left: auto;
        z-index: 20000;
        position: absolute;
        color: white;
        width: 100%;
        text-align: center;
        a {
            margin: 0 auto;
            display: block;
            width: 48px;
            height: 48px;
            line-height: 48px;
            border-radius: 50%;
            background: transparent;
            position: static;
            text-decoration: none;
            font-size: 1.5rem;
            color: $white;
            &:hover {
                color: $primary;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .page_block.app_scroll_down {
          display: block;
          pointer-events: none;

          i {
            pointer-events: all;
          }
        }
    }
}

.eyecatcher {
    &.home {
		@include media-breakpoint-up(md) {
			padding-bottom: 56.25%; /* 16:9 */
			height: 0;
			min-height: 0;
		}

		h1 {
			display: none;
			top: 0;
			left: 0;
			z-index: 20000;
			position: absolute;
			color: white;
			width: 100%;
			text-align: center;
			font-size: 4rem;

            @include media-breakpoint-up(md) {
                top: 15% !important;
            }
            @include media-breakpoint-up(lg) {
                top: 35% !important;
            }
            @include media-breakpoint-up(xl) {
                top: 40% !important;
            }
		}

		h2 {
			display: none;
			top: 50%;
			left: 0;
			z-index: 20000;
			position: absolute;
			color: white;
			width: 100%;
			text-align: center;
			font-size: 3rem;
		}

		.page_block.highlight {
			z-index: 3000001;
		}

		@include media-breakpoint-up(md) {
			.eyecatcher-owl-carousel { display: none;}
			h1 { display: block; }
			h2 { display: block; }
		}

		@include media-breakpoint-down(md) {
			h2 {
				margin-top: 20px;
			}
		}

		.page_block.vimeo_video {
			z-index: 10000;

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
    }
}

.highlight {
    max-width: 380px;
    position: absolute;
    background: $base-1;
    right: 15px;
    bottom: 15px;
    z-index: 11;
    border: 1px solid $base-3;
    border-radius: 8px;
    height: max-content;
    padding: 10px;
    @include media-breakpoint-down(sm) {
        max-width: none;
        left: 15px;
    }
    @include media-breakpoint-up(xl) {
        right: 80px;
        width: 100%;
    }
    .close-highlight {
        z-index: 10;
        position: absolute;
        top: 5px;
        right: 5px;

        .close-highlight-link {
            display: block;
            padding: 0 10px;
            color: $white;
            font-size: $font-size-base * 1.5;
            transition: 0.5s;
            &:hover {
                color: $red;
            }
        }
    }
    .card {
        border: none;

        &-body {
            background: $base-1;
            padding: 10px 0;

            .card-caption {
                .card-title {
                    font-size: $font-size-lg !important;
                    color: $secondary;
                    text-align: center;
                }
                .card-subtitle {
                    font-size: $font-size-base * 1.5;
                    color: $white;
                    text-align: center;
                }
                .card-description {
                    position: relative;
                    min-height: 100%;
                    max-height: 100%;
                    display: flex;
                    justify-content: center;
                    @include media-breakpoint-down(sm) {
                        height: 45px;
                        overflow: hidden;
                        &::after {
                            content: "";
                            display: block !important;
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            height: 50%;
                            background: linear-gradient(180deg, rgba($base-1, 0) 0%, rgba($base-1, 1) 100%);
                        }
                    }

                    &-content {
                        p {
                            font-size: $font-size-base_xs;
                            color: $white;
                            text-align: center;
                            margin-bottom: 0;

                            strong {
                                font-size: $font-size-base * 1.5;
                            }
                        }
                    }
                }
            }
            .card-buttons {
                text-align: center;

                .card-btn {
                    border-radius: 22px;
                    padding: 9px 45px;
                    background: $secondary;
                    font-size: $font-size-base_sm;
                    font-weight: 700;
                    border: none;

                    &:hover {
                        background: $white;
                        color: $secondary;
                    }
                }
            }
        }
    }
    &.show_detail_highlight {
        .card-subtitle {
            font-size: $font-size-base !important;
            color: $secondary !important;
        }
    }
}
