header {
	&.header {
		z-index: 300000;

		&::after {
			@include media-breakpoint-down(sm) {
				content: "";
				background: url("/images/curve-header-small.svg") no-repeat center/100%;
				position: absolute;
				bottom: -29px;
				width: 100%;
				height: 30px;
			}
		}
		.header-main {
			@include media-breakpoint-up(xl) {
				background: url("/images/headercurve.svg") no-repeat bottom/cover !important;
				min-height: 155px;
				transition: height 0.25s ease;
			}
			.page_block.logo {
				max-width: 140px;

				@include media-breakpoint-up(sm) {
					max-width: 179px;
				}
				@include media-breakpoint-up(xl) {
					margin-top: 10px;
				}
			}
			.navbar {
				.navbar-collapse.menu {
					@extend .mx-auto;
					flex-grow: 0;

					.navbar-nav {
						.nav-item {
							font-size: 16px;
							.dropdown-menu {
								border: none;
								.nav-item {
									white-space: nowrap;
								}
								.nav-link {
									color: $black;
									padding: 0.3rem 1rem;
									font-weight: 500;
									font-size: $font-size-base_sm;
								}
							}
							&:not(:last-child) {
								margin-right: 16px;
							}
							.nav-link {
								color: $primary;
								font-weight: 600;

								@include media-breakpoint-up(xl) {
									color: $base-1;
								}
								&:hover {
									color: $primary;
								}
							}
						}
					}
				}
				.show_tickets_logo {
					margin-right: 0;

					&:hover {
						opacity: 0.7;
					}
					@include media-breakpoint-up(sm) {
						margin-right: 26px;
					}
				}
				.page_block.searchbox {
					display: flex;
					align-items: center;

					.search-icon {
						&:hover {
							opacity: 0.7;
						}
					}
				}
				@include media-breakpoint-down(lg) {
					padding: 16px;
					z-index: 1;

					&-toggler {
						margin-right: 0;
						order: 1;
						min-width: 45px;

						@media screen and (min-width: 340px) {
							// Specific for iphone 5 and smallre devices
							margin-right: 40px;
						}
						&[aria-expanded="true"] {
							.fa-bars {
								&::before {
									content: "\f00d";
								}
							}
						}
						.navbar-toggler-icon {
							color: $black;
							background: none;
						}
					}
					&-nav {
						display: block;
						padding: 30px 20px 10px;
					}
					.page_block.logo {
						order: 2;
					}
					.show_tickets_logo {
						order: 3;
						flex: 1;
						display: flex;
						justify-content: flex-end;
						margin-right: 15px;
						margin-left: 10px
					}
					.page_block.searchbox {
						order: 4;
					}
					&-collapse {
						order: 5;

						.navbar-nav {
							> li {
								&:not(:last-child) {
									border-bottom: 1px solid $secondary;
								}
								> a {
									padding: 8px 0;
									font-size: $font-size-base_sm;
								}
							}
						}
					}
				}
				@include media-breakpoint-up(xl) {
					padding: 0 50px;
				}
			}
		}
		.page_block.searchbox {
			.search-box-form {
				display: none;
			}
		}
		&.sticky {
			background: $white;
			position: fixed;
			width: 100%;
			top: 0;

			.header-main {
				min-height: 100%;
				transition: height 0.25s ease;

				.logo {
					a {
						width: 65px;
						height: 40px;
						background: url("/images/eyes.svg") no-repeat top/cover;

						img {
							display: none;
						}
					}
					max-width: 179px;
					margin-top: 10px;
					margin-bottom: 10px;
				}

				.navbar {
					.show_tickets_logo,
					.search-icon {
						&,
						a {
							background: none !important;

							img {
								display: block !important;
							}
						}
					}
				}
			}
		}
	}
}

&.home {
	header {
		&.header {
			.header-main {
				background: transparent !important; // !important needed to override base css
				min-height: 100%;

				.page_block.logo {
					max-width: 140px;

					@include media-breakpoint-up(sm) {
						max-width: 240px;
					}
					@include media-breakpoint-up(xl) {
						margin-top: 40px;
					}
				}
				.navbar {
					.navbar-collapse.menu {
						@extend .mx-auto;
						.navbar-nav {
							.nav-item {
								.dropdown-menu {
									border: none;
									.nav-item {
										white-space: nowrap;
									}
									.nav-link {
										color: $black;
										padding: 0.3rem 1rem;
										font-weight: 500;
										font-size: $font-size-base_sm;
									}
								}
								.nav-link {
									color: $primary;
									font-weight: 600;

									@include media-breakpoint-up(xl) {
										color: $white;
									}
									&:hover {
										opacity: 0.7;
									}
								}
							}
						}
					}
					.show_tickets_logo {
						@include media-breakpoint-up(xl) {
							a {
								width: 62px;
								height: 33px;
								display: inline-block;
								background: url("/images/btn_tickets_white_text.svg") no-repeat top/cover;

								&:hover {
									opacity: 0.7;
								}
								img {
									display: none;
								}
							}
							@include media-breakpoint-up(sm) {
								margin-right: 26px;
							}
						}
					}
					.page_block.searchbox {
						@include media-breakpoint-up(xl) {
							.search-icon {
								width: 24px;
								height: 24px;
								display: inline-block;
								background: url("/images/search_eyecon_white.svg") no-repeat top/cover;

								img {
									display: none;
								}
							}
						}
					}
				}
			}
			&.sticky {

				@include media-breakpoint-up(xl) {
					background: $white;
				}

				.header-main {
					.logo {
						margin-top: 10px;
					}
					.navbar {
						.navbar-collapse.menu {
							.navbar-nav {
								.nav-item {
									.dropdown-menu {
										border: none;

										.nav-link {
											color: $black;
											padding: 0.3rem 1rem;
											font-weight: 500;
											font-size: $font-size-base_sm;
										}
									}
									&.first {
										.nav-link {
											color: $primary;
										}
									}
									.nav-link {
										color: $base-1;

										&:hover {
											color: $primary;
											opacity: 1;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
