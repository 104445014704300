&.show_location_overview {
	.intro-section {
		@include media-breakpoint-up(xl) {
			padding-top: 160px;
		}
	}
	.grid {
		margin-top: 30px;
		.item {
			@include make-col(12);
			margin-bottom: 15px;
			.card {
				.card-body {
					flex-direction: row !important;
					flex-wrap: wrap;
					padding: 0 0 15px 0;
					border-bottom: 1px solid rgba($black, 0.5);
					.card-caption {
						.card-title-link {
							.card-title {
								font-size: 1rem;
							}
						}
						.card-subtitle {
							margin-bottom: 0;
							font-size: 1rem;
							font-weight: 400;
						}
					}
					.card-buttons {
						margin-top: auto;
						.card-btn {
							padding: 0.375rem 0.75rem;
							text-transform: none;
						}
					}
				}
			}
		}
	}
}
