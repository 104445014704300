// common element styles
p {
	font-size: $font-size-base;
	font-weight: 500 !important;
}
img {
	max-width: 100%;
}
.mx-auto {
	margin-left: auto;
	margin-right: auto;
}
h1 {
	@include media-breakpoint-down(sm) {
		font-size: $font-size-base * 2 !important;
	}
}
h2 {
	@include media-breakpoint-down(sm) {
		font-size: $font-size-base * 1.125 !important;
	}
}
h3 {
	@include media-breakpoint-down(sm) {
		font-size: $font-size-base * 1.5;
	}
}

// Common reusable placeholders classes
.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}
.list-default {
	margin: 0;
	padding: 0;
	list-style: none;
}
.list-item {
	@extend .list-default;
	display: flex;
	flex-wrap: wrap;

	li {
		display: flex;
		background: rgba($white, 0.4);
		border-radius: 15px;
		padding: 5px 16px;

		a {
			color: $base-1;
			font-weight: 700;
			font-size: $font-size-base_xxs;
			text-decoration: none;

			@include media-breakpoint-up(sm) {
				font-size: $font-size-base_sm;
			}
			&:hover {
				opacity: 0.6;
			}
		}
		&:not(:last-child) {
			margin: 0 10px 10px 0;
		}
		&:last-child {
			margin: 0 0 10px 0;
		}
	}
}
.info-list {
	@extend .list-default;

	li {
		color: $white;
		margin-bottom: 5px;

		i {
			margin-right: 10px;
			min-width: 20px;
			text-align: center;
		}
		span {
			color: $white;
		}
	}
}

.view_offer {
	font-weight: 500;
	display: flex;
	align-items: center;

	a {
		font-size: $font-size-base_xxs;
		font-weight: 700;
		text-transform: uppercase;
		color: $base-1;
		position: relative;
		padding-right: 20px;

		@include media-breakpoint-up(sm) {
			font-size: $font-size-base_sm;
		}
		&:hover {
			color: $primary;
			text-decoration: none;

			&::after {
				right: -10px;
			}
		}

		&::after {
			position: absolute;
			top: 50%;
			right: 0;
			content: "\f0a9";
			transform: translateY(-50%);
			font-size: $font-size-base_xxs;
			font-family: $font-awesome;
			font-weight: normal;
			transition: all 300ms;

			@include media-breakpoint-up(sm) {
				font-size: $font-size-base_sm;
				margin-top: 2px;
			}
		}
	}
}

.content-blocks.contact-content-blocks {
	.container {
		@include media-breakpoint-up(sm) {
			max-width: 720px !important;
		}
		@include media-breakpoint-up(xl) {
			max-width: 1140px !important;
		}
	}
}
.contentblock-card {
	.card-image {
		border: 1px solid rgba($base-4, 0.5);

		.card-img {
			display: flex;
			margin: auto;
		}
	}
	.card-body {
		padding: 15px 0;

		.card-caption {
			.card-title {
				color: $primary;
				font-size: $font-size-base * 1.5;

				@include media-breakpoint-up(sm) {
					font-size: $h3-font-size;
				}
			}
			.card-description {
				&-content {
					p {
						font-size: $font-size-base_sm;
					}
				}
			}
		}
	}
}

.custom-list-item {
	padding-left: 15px !important;
	margin-top: 25px;
	list-style: none;

	li {
		font-size: $font-size-base_sm;
		margin-bottom: 5px;
		font-weight: 500;

		&::before {
			content: "\f0a9" !important;
			width: 16px;
			height: 16px;
			margin-right: 10px;
			font-family: $font-awesome;
			font-weight: normal !important;
			color: $base-1 !important;
			transition: all 300ms;

			@include media-breakpoint-up(sm) {
				font-size: $font-size-base_sm !important;
			}
		}
	}
}
