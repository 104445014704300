/* reset article_2_columns like .show_detail_story */
.main .article_2_columns {
	padding: 0 30px !important;

	.container-two-columns {
		display: block !important;

		.container-holder {
			display: flex;
			margin-left: -15px;
			margin-right: -15px;
		}
	}
}

.show_detail_story,
.article_2_columns {
	.show-story-item,
	.container-holder {
		&-media,
		.column.one {
			&-image,
			img {
				min-height: 100%;
				object-fit: cover;
				box-shadow: 3px 3px 6px rgba($black, 0.16);
				border-radius: 8px;
				border: 1px solid rgba($white, 0.5);
			}
		}
	}

	.show-story-item:first-of-type,
	.container-two-columns:first-of-type .container-holder {
		margin-bottom: 40px;

		@include media-breakpoint-up(lg) {
			margin-bottom: 60px;
		}

		.show-story-item-media,
		.column.one {
			@include media-breakpoint-down(md) {
				order: 2;
			}
		}

		.show-story-item-content,
		.column.two {
			padding-top: 60px;

			@include media-breakpoint-down(md) {
				order: 1;
			}
			@include media-breakpoint-up(lg) {
				//max-width: 420px !important;
				margin: 0 auto;
			}
			h3 {
				position: relative;

				&::before {
					background: url("/images/eyes-right.svg") no-repeat top/cover;
					content: "";
					position: absolute;
					top: -65px;
					right: 0;
					width: 85px;
					height: 52px;

					@include media-breakpoint-up(xl) {
						right: -65px;
					}
				}
			}
		}
	}

	.show-story-item:last-of-type,
	.container-two-columns:last-of-type .container-holder {
		margin-bottom: 80px;

		@include media-breakpoint-up(sm) {
			margin-bottom: 100px;
		}
		.show-story-item-content,
		.column.two {
			padding-top: 30px;

			//h3,
			//h4,
			//p {
			//	@include media-breakpoint-up(lg) {
			//		max-width: 420px;
			//		margin: 0 auto;
			//	}
			//}
		}
	}

	&-content,
	.column.two {
		h4 {
			font-size: $font-size-base * 1.25;
			line-height: 1.6;
			margin-bottom: 20px;

			@include media-breakpoint-up(sm) {
				font-size: $font-size-base * 1.5;
			}
		}
	}
}

.page_block.show_detail_story,
.article_2_columns {

	// Odd / Even switch
	.show-story-item:nth-of-type(even),
	.container-two-columns:nth-of-type(even) .container-holder {
		.show-story-item-media,
		.column.one {
			order: 1;
		}
	}

	.show-story-item,
	.container-holder {
		@include make-row();

		.show-story-item-media,
		.column.one {
			@include make-col-ready();
			@include make-col(12);

			@include media-breakpoint-up(lg) {
				@include make-col(6);
			}

			.show-story-item-media-image,
			.item-media-image,
			img {
				max-width: 100%;
			}
		}

		.show-story-item-content,
		.column.two {
			@include make-col-ready();
			@include make-col(12);

			@include media-breakpoint-up(lg) {
				@include make-col(6);
			}

			padding-left: 15px !important;
		}
	}
}
