&.show_overview {
    main {
        padding: 0 !important; // !important needed to override base css
        margin: 0 !important; // !important needed to override base css
        position: relative;
        z-index: 1;

        @include media-breakpoint-up(xl) {
            padding: 80px 0 0 !important; // !important needed to override base css
        }
        .content {
            padding: 60px 0 70px !important; // !important needed to override base css
            margin-top: -130px;
            z-index: 11;

            @include media-breakpoint-up(sm) {
                margin-top: -150px;
            }
            @include media-breakpoint-up(xl) {
                padding: 60px 0 60px !important; // !important needed to override base css
            }
        }
    }
    section {
        &.intro-section.show {
            padding: 50px 0 140px !important;
            position: relative;
            z-index: -1;
            background-color: $secondary;
            margin-top: 0;

            @include media-breakpoint-up(sm) {
                padding: 30px 0 180px !important; // !important needed to override base css
            }
            @include media-breakpoint-up(xl) {
                padding: 70px 0 180px !important; // !important needed to override base css
            }

            &::after {
                content: "";
                background: url(/images/curve-blue.svg) no-repeat top/100%;
                position: absolute;
                bottom: -198px;
                width: 100%;
                height: 198px;
                right: 0;
                z-index: -1;
            }
            .container-holder {
                padding-top: 50px;
                text-align: center;

                h1 {
                    font-size: $font-size-base * 2.5;

                    @include media-breakpoint-up(md) {
                        font-size: $font-size-base * 3.75;
                    }
                }
                h1,
                h2 {
                    color: $white;
                }
            }
        }
    }
    .grid {
        &.show_overview {
            .item {
                @include media-breakpoint-up(sm) {
                    margin: 0 0 60px 0 !important;
                }

                .card {
                    border-radius: 8px !important; // !important needed to override base css

                    .card-body {
                        padding: 10px 0 !important; // !important needed to override base css

                        .card-caption {
                            .card-title-link {
                                order: 1;
                                color: $base-1;

                                &:hover {
                                    .card-title {
                                        color: $primary;
                                    }
                                }
                                .card-title {
                                    color: $base-1;
                                }
                            }
                            .card-subtitle {
                                order: 2;
                                color: $base-1;
                                margin-top: 0;
                                margin-bottom: 0;
                            }
                            .card-description {
                                &-content {
                                    .info-extra-list {
                                        @extend .list-default;

                                        &-item {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 5px;

                                            i {
                                                margin-right: 10px;
                                                min-width: 20px;
                                                text-align: center;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
